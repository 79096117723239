import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Popover, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TimePeriod } from 'common/interfaces/reports';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';

interface Props {
  timePeriod: TimePeriod;
  onTimePeriodSelected: (arg0: TimePeriod) => void;
}

export default function ImportalDatePicker({ timePeriod, onTimePeriodSelected }: Props) {
  const theme = useTheme();
  const [timePeriodSelectionError, setTimePeriodSelectionError] = useState<string | null>();

  const validateDateSelections = (startDate: Date | null, endData: Date | null): boolean => {
    if (!startDate || !endData) {
      setTimePeriodSelectionError('Please select both start and end dates');
      return false;
    } else if (startDate > endData) {
      setTimePeriodSelectionError('Start date cannot be after end date');
      return false;
    } else {
      setTimePeriodSelectionError(null);
      return true;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;
  const handleStartDateChange = (date: Date | null) => {
    if (validateDateSelections(date, timePeriod.periodEndDate)) {
      onTimePeriodSelected({ ...timePeriod, periodStartDate: date });
    }
  };
  const handleEndDateChange = (date: Date | null) => {
    if (validateDateSelections(timePeriod.periodStartDate, date)) {
      onTimePeriodSelected({ ...timePeriod, periodEndDate: date });
    }
  };

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        {timePeriod.periodStartDate && timePeriod.periodEndDate
          ? `${formatMMMDDYYYYDate(timePeriod.periodStartDate!.toString())} to ${formatMMMDDYYYYDate(timePeriod.periodEndDate!.toString())}`
          : 'Select Dates'}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '20px' }}>
          <div>
            <div>Select Dates</div>
            <div
              style={{
                display: 'flex',
                gap: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label="Start Date"
                  value={timePeriod.periodStartDate}
                  onChange={handleStartDateChange}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label="End Date"
                  value={timePeriod.periodEndDate}
                  onChange={handleEndDateChange}
                />
              </LocalizationProvider>
            </div>
            {timePeriodSelectionError && (
              <ul>
                <li style={{ color: 'red' }}>{timePeriodSelectionError}</li>
              </ul>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}
