import React, { useContext, useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link, MenuItem, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import './ShipmentsTable.css';
import ImportalTable from '@/shared-components/ImportalTable/ImportalTable';
import { ColumnConfig, TableConfig } from 'common/interfaces/importalTable';
import { useLocation } from 'react-router-dom';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import FloatingTooltip from '@/shared-components/FloatingTooltip/FloatingTooltip';
import {
  defaultShipmentsTableColumns,
  InternalShipmentStatus,
  InternalShipmentStatusColor,
  InternalShipmentStatusText,
  Shipment,
  ShipmentTransportationType,
} from 'common/interfaces/shipment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FlightIcon from '@mui/icons-material/Flight';
import TrainIcon from '@mui/icons-material/Train';
import EditIcon from '@mui/icons-material/Edit';
import { Mode } from 'common/interfaces/business';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { useAPI } from '@/api/APIContext';
import { ShipmentsContext } from './Shipments';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import {
  PredefinedShipmentsTableViewName,
  predefinedShipmentsTableViews,
  UserSettingName,
} from 'common/interfaces/user';
import { formatHumanReadableDate } from 'common/utilities/shipmentUtility';

function createData(
  {
    _id,
    referenceNumber,
    partnerReferenceNumber,
    businessName,
    internalStatus,
    mbl,
    hbl,
    etd,
    eta,
    dueDate,
    isfOnFile,
    customsCleared,
    notes,
    type,
    billing,
    billed,
  }: any,
  Id: number
) {
  return {
    _id,
    referenceNumber,
    partnerReferenceNumber,
    businessName,
    internalStatus,
    mbl,
    hbl,
    etd,
    eta,
    dueDate,
    isfOnFile,
    customsCleared,
    notes,
    type,
    billing,
    billed,
  };
}

const Row = ({ row, api, columnConfig }: { row: any; api: any; columnConfig: ColumnConfig[] }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [shipment, setShipment] = useState<any>({} as any);
  const [entry, setEntry] = useState<any>({} as any);
  const { refreshShipments, setLoadingStatus, setLoadingText, setSuccessText, setErrorText } =
    useContext(ShipmentsContext);

  const [modifyMode, setModifyMode] = useState<any>({
    internalStatus: { mode: Mode.VIEW, hover: false },
    isfOnFile: { mode: Mode.VIEW, hover: false },
    customsCleared: { mode: Mode.VIEW, hover: false },
    notes: { mode: Mode.VIEW, hover: false },
    billing: { mode: Mode.VIEW, hover: false },
    billed: { mode: Mode.VIEW, hover: false },
  });

  useEffect(() => {
    setShipment(row);
  }, [row]);

  const renderISFInformation = (shipment) => {
    if (shipment.type === ShipmentTransportationType.AIR || shipment.type === ShipmentTransportationType.TRUCK) {
      return <>N/A</>;
    } else {
      return shipment.isfOnFile === 'Yes' ? (
        <CheckIcon style={{ color: 'green' }} />
      ) : (
        <CloseIcon style={{ color: 'red' }} />
      );
    }
  };

  const renderBooleanIcons = (property: boolean) => {
    if (property) {
      return <CheckIcon style={{ color: 'green' }} />;
    } else {
      return <CloseIcon style={{ color: 'red' }} />;
    }
  };

  const updateShipment = (shipment, name) => {
    setLoadingText('Updating shipment...');
    setLoadingStatus(LoadingStatus.LOADING);
    setShipment(shipment);
    api
      .updateShipment(shipment._id, shipment)
      .then((response) => {
        refreshShipments();
        setModifyMode((prev) => ({ ...prev, [name]: { ...prev[name], mode: Mode.VIEW } }));
        setSuccessText(`${shipment.referenceNumber} updated successfully`);
        setLoadingStatus(LoadingStatus.SUCCESS);
      })
      .catch((error) => {
        setModifyMode((prev) => ({ ...prev, [name]: { ...prev[name], mode: Mode.VIEW } }));
        setErrorText(`Error updating shipment ${shipment.referenceNumber}`);
        setLoadingStatus(LoadingStatus.ERROR);
      });
  };

  const renderIconBasedOnTransportType = (type: ShipmentTransportationType) => {
    switch (type) {
      case ShipmentTransportationType.OCEAN:
        return (
          <FloatingTooltip title="Ocean" placement="top">
            <DirectionsBoatIcon sx={{ fontSize: '20px' }} />
          </FloatingTooltip>
        );
      case ShipmentTransportationType.AIR:
        return (
          <FloatingTooltip title="Air" placement="top">
            <FlightIcon />
          </FloatingTooltip>
        );
      case ShipmentTransportationType.TRUCK:
        return (
          <FloatingTooltip title="Truck" placement="top">
            <LocalShippingIcon />
          </FloatingTooltip>
        );
      case ShipmentTransportationType.RAIL:
        return (
          <FloatingTooltip title="Rail" placement="top">
            <TrainIcon />
          </FloatingTooltip>
        );
      default:
        break;
    }
  };

  const renderStatusChip = (status: InternalShipmentStatus) => {
    return (
      <div className="status-chip-container">
        <Chip
          label={InternalShipmentStatusText[status]}
          sx={{
            backgroundColor: InternalShipmentStatusColor[status],
            color: 'white',
            fontWeight: 'bold',
            paddingLeft: '5px',
            paddingRight: '5px',
            fontSize: '12px',
            height: '26px',
          }}
        />
      </div>
    );
  };

  const handleReferenceNumberClick = (id: string) => {
    const basePath = '/broker/dashboard/shipments';
    const url = `${basePath}/${id}`;
    window.open(url, '_blank');
  };

  const handleEntryChange = (name, value) => {
    setEntry({ ...entry, [name]: value });
  };

  const handleShipmentChange = (name, value) => {
    setShipment({ ...shipment, [name]: value });
  };

  const renderField = (name, value) => {
    // Ensure non-empty rendering even when value is an empty string
    const displayValue = value === '' ? '\u00A0' : value; // Use a non-breaking space as a fallback

    if (modifyMode[name]?.mode === Mode.EDIT) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {(() => {
            switch (name) {
              case 'billed':
                return (
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    value={value ? 'Yes' : 'No'}
                    onChange={(e) => handleShipmentChange(name, e.target.value === 'Yes')}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                );
              case 'isfOnFile':
                return (
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={(e) => handleShipmentChange(name, e.target.value)}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="N/A">N/A</MenuItem>
                  </TextField>
                );
              case 'customsCleared':
                return (
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    value={value ? 'Yes' : 'No'}
                    onChange={(e) => handleShipmentChange(name, e.target.value === 'Yes')}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                );
              case 'type':
                return (
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={(e) => handleShipmentChange(name, e.target.value)}
                  >
                    <MenuItem value={ShipmentTransportationType.OCEAN}>Ocean</MenuItem>
                    <MenuItem value={ShipmentTransportationType.AIR}>Air</MenuItem>
                    <MenuItem value={ShipmentTransportationType.TRUCK}>Truck</MenuItem>
                    <MenuItem value={ShipmentTransportationType.RAIL}>Rail</MenuItem>
                  </TextField>
                );
              case 'entryNumber':
                return (
                  <TextField
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleEntryChange(name, e.target.value)}
                    value={value}
                  />
                );
              case 'internalStatus':
                return (
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={(e) => handleShipmentChange(name, e.target.value)}
                  >
                    {Object.keys(InternalShipmentStatus).map((statusKey) => (
                      <MenuItem key={statusKey} value={InternalShipmentStatus[statusKey]}>
                        {InternalShipmentStatusText[statusKey]}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              default:
                return (
                  <TextField
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleShipmentChange(name, e.target.value)}
                    value={value}
                  />
                );
            }
          })()}
          <IconButton
            onClick={() => setModifyMode((prev) => ({ ...prev, [name]: { ...prev[name], mode: Mode.VIEW } }))}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <IconButton size="small">
            <CheckIcon sx={{ color: 'green' }} onClick={() => updateShipment(shipment, name)} fontSize="small" />
          </IconButton>
        </div>
      );
    } else {
      return modifyMode[name] ? (
        <div
          onMouseEnter={() => setModifyMode((prev) => ({ ...prev, [name]: { ...prev[name], hover: true } }))}
          onMouseLeave={() => setModifyMode((prev) => ({ ...prev, [name]: { ...prev[name], hover: false } }))}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <FloatingTooltip title="Edit">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {(() => {
                switch (name) {
                  case 'type':
                    return renderIconBasedOnTransportType(value);
                  case 'isfOnFile':
                    return renderISFInformation(shipment);
                  case 'customsCleared':
                    return renderBooleanIcons(shipment.customsCleared);
                  case 'billed':
                    return renderBooleanIcons(shipment.billed);
                  case 'internalStatus':
                    return renderStatusChip(value);
                  default:
                    return <span style={{ minWidth: '30px' }}>{displayValue}</span>;
                }
              })()}
            </div>
          </FloatingTooltip>
          {modifyMode[name].hover && (
            <IconButton
              size="small"
              onClick={() =>
                setModifyMode((prev) => ({
                  ...prev,
                  [name]: { ...prev[name], mode: Mode.EDIT },
                }))
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      ) : (
        <span>{displayValue}</span>
      );
    }
  };

  const renderPartnerIcon = (partnerReferenceNumber) => {
    let iconSrc = `https://img.logo.dev/importal.com?token=${process.env.REACT_APP_LOGO_DEV_API_KEY}`;
    let toolTipText = 'Direct Customer';

    if (partnerReferenceNumber) {
      if (partnerReferenceNumber.includes('SILQ')) {
        iconSrc = `https://img.logo.dev/onesilq.com?token=${process.env.REACT_APP_LOGO_DEV_API_KEY}`;
        toolTipText = 'Silq';
      }
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FloatingTooltip title={toolTipText}>
          <img height="20" width="20" src={iconSrc} />
        </FloatingTooltip>
      </div>
    );
  };

  return (
    <React.Fragment>
      <TableRow className="broker-shipment-table-row">
        {/* Static spacer cell */}
        <TableCell sx={{ width: '20px!important' }}></TableCell>

        {/* Iterate over columnConfig and conditionally render cells based on columnConfig */}
        {columnConfig
          .filter((config) => config.isVisible !== false) // Only include visible columns
          .map((config) => {
            switch (config.name) {
              case 'referenceNumber':
                return (
                  <TableCell key={config.name} sx={{ width: '50px', textWrap: 'nowrap' }}>
                    <Link
                      sx={{ textDecoration: 'none' }}
                      component="button"
                      onClick={() => window.open(`/broker/dashboard/shipments/${shipment._id}`, '_blank')}
                    >
                      {renderField('referenceNumber', shipment.referenceNumber)}
                    </Link>
                  </TableCell>
                );
              case 'partnerReferenceNumber':
                return <TableCell key={config.name}>{renderPartnerIcon(shipment.partnerReferenceNumber)}</TableCell>;
              case 'businessName':
                return (
                  <TableCell
                    key={config.name}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '50px',
                      minWidth: '0px!important',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {renderField('businessName', shipment.businessName)}
                  </TableCell>
                );
              case 'mbl':
                return (
                  <TableCell key={config.name} sx={{ width: '170px', whiteSpace: 'nowrap' }}>
                    {renderField('mbl', shipment.mbl)}
                  </TableCell>
                );
              case 'type':
                return (
                  <TableCell key={config.name} sx={{ width: '20px' }}>
                    {renderIconBasedOnTransportType(shipment.type)}
                  </TableCell>
                );
              case 'etd':
                return (
                  <TableCell key={config.name} sx={{ width: '100px' }}>
                    {renderField('etd', formatHumanReadableDate(shipment.etd))}
                  </TableCell>
                );
              case 'dueDate':
                return (
                  <TableCell key={config.name} sx={{ width: '100px' }}>
                    {renderField('dueDate', formatHumanReadableDate(shipment.dueDate))}
                  </TableCell>
                );
              case 'eta':
                return (
                  <TableCell key={config.name} sx={{ width: '100px' }}>
                    {renderField('eta', formatHumanReadableDate(shipment.eta))}
                  </TableCell>
                );
              case 'isfOnFile':
                return (
                  <TableCell key={config.name} sx={{ width: '20px' }}>
                    {renderField('isfOnFile', shipment.isfOnFile)}
                  </TableCell>
                );
              case 'customsCleared':
                return (
                  <TableCell key={config.name} sx={{ width: '20px' }}>
                    {renderField('customsCleared', shipment.customsCleared)}
                  </TableCell>
                );
              case 'billed':
                return (
                  <TableCell key={config.name} sx={{ width: '20px' }}>
                    {renderField('billed', shipment.billed)}
                  </TableCell>
                );
              case 'internalStatus':
                return (
                  <TableCell key={config.name} sx={{ width: '130px' }}>
                    {renderField('internalStatus', shipment.internalStatus)}
                  </TableCell>
                );
              case 'notes':
                return (
                  <TableCell key={config.name} sx={{ textWrap: 'nowrap' }}>
                    {renderField('notes', shipment.notes)}
                  </TableCell>
                );
              default:
                return null;
            }
          })}
      </TableRow>
    </React.Fragment>
  );
};

interface ShipmentTableProps {
  rows: Shipment<any>[];
  isLoading: boolean;
  createShipmentButton?: React.JSX.Element;
}

const ShipmentTable = ({ rows, isLoading, createShipmentButton }: ShipmentTableProps) => {
  const activeUser = useActiveUser();
  const [shipmentTableViews, setShipmentTableViews] = useState<TableConfig[]>([]);

  const [shipmentTableConfig, setShipmentTableConfig] = useState<TableConfig>({
    columns: defaultShipmentsTableColumns,
    sortConfig: [],
    filterConfig: {},
  });

  useEffect(() => {
    async function fetchUserSettings() {
      try {
        const userViews = await activeUser.getUserSetting(UserSettingName.SHIPMENT_TABLE_VIEWS);

        let updatedViews = predefinedShipmentsTableViews;

        if (userViews && Array.isArray(userViews)) {
          // Merge user views with defaults, prioritizing user views
          updatedViews = predefinedShipmentsTableViews.map((defaultView) => {
            const userView = userViews.find((view) => view.viewName === defaultView.viewName);
            return userView || defaultView;
          });

          setShipmentTableViews(updatedViews);

          const defaultView =
            userViews.find((view) => view.viewName === PredefinedShipmentsTableViewName.ALL) ||
            predefinedShipmentsTableViews.find((view) => view.viewName === PredefinedShipmentsTableViewName.ALL);

          if (defaultView) {
            setShipmentTableConfig({
              columns: defaultView.columns,
              sortConfig: defaultView.sortConfig,
              filterConfig: defaultView.filterConfig,
            });
          }
        } else {
          // If no user views are found, fall back to predefined defaults
          setShipmentTableViews(predefinedShipmentsTableViews);
          const defaultView = predefinedShipmentsTableViews.find(
            (view) => view.viewName === PredefinedShipmentsTableViewName.ALL
          );

          if (defaultView) {
            setShipmentTableConfig({
              columns: defaultView.columns,
              sortConfig: defaultView.sortConfig,
              filterConfig: defaultView.filterConfig,
            });
          }
        }
      } catch (error) {
        console.error('Error fetching user settings:', error);
      }
    }

    fetchUserSettings();
  }, [activeUser]);

  const api = useAPI();

  return (
    <div className="broker-shipments-table-container">
      <ImportalTable
        data={rows}
        rowGenerator={(row, index) => (
          <Row key={`row-${index}`} api={api} row={createData(row, index)} columnConfig={shipmentTableConfig.columns} />
        )}
        tableConfig={shipmentTableConfig}
        setTableConfig={setShipmentTableConfig}
        predefinedViews={shipmentTableViews}
        loading={isLoading}
        topRightButton={createShipmentButton}
        useSettings={true}
      />
    </div>
  );
};
export default ShipmentTable;
