import React, { createContext, useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useAPI } from '@/api/APIContext';
import './BusinessPage.css';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { BusinessUser, BusinessWithDocuments, ComplianceProfile, Mode } from 'common/interfaces/business';
import BusinessAdminTab from '@/broker-app/pages/businesses/BusinessAdminTab';
import { UsagePlanAny, UsagePlanMetadata } from 'common/interfaces/plan';
import { Entry } from 'common/interfaces/entry';
import { Link } from 'common/interfaces/session';
import ImportalTabs from '@/shared-components/ImportalTabs/ImportalTabs';
import Checkbox from '@mui/material/Checkbox';
import { useMatch } from 'react-router';
import { CustomsOnboarding, CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';
import BusinessReportingTab from '@/broker-app/pages/businesses/BusinessReportingTab';
import { Report } from 'common/interfaces/reports';
import ShipmentsTable from '../shipments/ShipmentsTable';
import { ShipmentWithDocumentIds } from 'common/interfaces/shipment';
import ManageDocuments from '@/shared-components/ManageDocuments/ManageDocuments';
import { businessDocumentsFilter, Document } from 'common/interfaces/document';
import ClassificationRequestsTab from '@/broker-app/pages/businesses/ClassificationRequestsTab';
import { FieldType, renderImportalField } from '@/shared-components/renderBusinessFields';
import renderModeIconButton from '@/shared-components/renderModeIcons';

export interface IBusinessPageContext {
  plan?: UsagePlanAny;
  refreshPlan: () => void;
  planMetadata?: UsagePlanMetadata;
  refreshPlanMetadata: () => void;
  users: any[];
  refreshUsers: () => void;
  business?: BusinessWithDocuments;
  refreshBusiness: () => void;
  entries?: Entry[];
  links: Link[];
  refreshLinks: () => void;
  customsOnboarding?: CustomsOnboarding;
  refreshCustomsOnboarding: () => void;
  isCustomsOnboarded?: boolean;

  reports?: Report[];
  refreshReports: () => void;
  documents?: Document[];
}

export const BusinessPageContext = createContext<IBusinessPageContext>({
  plan: undefined,
  refreshPlan: () => {},
  planMetadata: undefined,
  refreshPlanMetadata: () => {},
  users: [],
  refreshUsers: () => {},
  business: undefined,
  refreshBusiness: () => {},
  entries: [],
  links: [],

  refreshLinks: () => {},
  customsOnboarding: undefined,
  refreshCustomsOnboarding: () => {},
  isCustomsOnboarded: false,

  reports: undefined,
  refreshReports: () => {},
  documents: [],
});

type CardName = 'businessInfo' | 'businessCompliance' | 'businessBilling';

const { Provider } = BusinessPageContext;

export default function BusinessPage() {
  const api = useAPI();
  const theme = useTheme();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const match = useMatch({
    path: '/broker/dashboard/businesses/:businessId',
  });
  const [entries, setEntries] = useState<Entry[]>([]);
  const [plan, setPlan] = useState<UsagePlanAny | undefined>(undefined);
  const [planMetadata, setPlanMetadata] = useState<UsagePlanMetadata | undefined>(undefined);
  const [users, setUsers] = useState<BusinessUser[]>([]);
  const [links, setLinks] = useState<Link[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [shipments, setShipments] = useState<ShipmentWithDocumentIds[]>([]);
  const [customsOnboarding, setCustomsOnboarding] = useState<CustomsOnboarding | undefined>(undefined);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [business, setBusiness] = useState<BusinessWithDocuments | undefined>();
  const [complianceProfile, setComplianceProfile] = useState<ComplianceProfile | undefined>();
  const [businessSummaryMode, setBusinessSummaryMode] = useState({
    businessInfo: Mode.VIEW,
    businessCompliance: Mode.VIEW,
    businessBilling: Mode.VIEW,
  });

  const handleBusinessInfoChange = (name: keyof BusinessWithDocuments, value: any) => {
    setBusiness({ ...business, [name]: value } as BusinessWithDocuments);
  };

  const handleComplianceProfileChange = (name: keyof ComplianceProfile, value: any) => {
    setComplianceProfile({ ...(complianceProfile || ({} as ComplianceProfile)), [name]: value });
  };

  const saveDocumentsToBusiness = (documents: Document[]) => {
    const updatedBusiness: BusinessWithDocuments = {
      ...(business as BusinessWithDocuments),
      documents: documents,
    };
    updateBusiness(updatedBusiness);
  };

  const updateBusiness = (updatedBusiness: BusinessWithDocuments, cardName?: string) => {
    const businessId = business?._id?.toString();
    setLoadingStatus(LoadingStatus.LOADING);
    setLoadingText('Updating business...');
    if (businessId) {
      api
        .updateBusiness(businessId, updatedBusiness)
        .then((response) => {
          handleChangeMode(setBusinessSummaryMode, Mode.VIEW, cardName);
          setLoadingStatus(LoadingStatus.SUCCESS);
          setSuccessText('Successfully updated business.');
          refreshBusiness();
        })
        .catch((error) => {
          console.error('error updating business', error);
          setLoadingStatus(LoadingStatus.ERROR);
          setErrorText(`Error updating business: ${error}`);
        });
    }
  };

  const updateCompliance = (updatedCompliance, cardName) => {
    const businessId = business?._id?.toString();
    setLoadingStatus(LoadingStatus.LOADING);
    setLoadingText('Updating business...');

    if (businessId) {
      api
        .updateComplianceProfile(businessId, updatedCompliance)
        .then(({ data }) => {
          setComplianceProfile(data);
          handleChangeMode(setBusinessSummaryMode, Mode.VIEW, cardName);
          setLoadingStatus(LoadingStatus.SUCCESS);
          setSuccessText('Successfully updated business compliance.');
          refreshBusiness();
        })
        .catch((error) => {
          console.error('error updating compliance profile', error);
          setLoadingStatus(LoadingStatus.ERROR);
          setErrorText(`Error updating business compliance: ${error}`);
        });
    }
  };

  const handleChangeMode = (setModeFunction, newMode, cardName) => {
    setModeFunction((prevMode) => ({
      ...prevMode,
      [cardName]: newMode,
    }));
  };

  const refreshUsers = () => {
    api
      .getBusinessUsers(match?.params.businessId!)
      .then(({ data: users }) => {
        setUsers(users);
      })
      .catch((err) => {
        console.error('error getting business users for BusinessPage');
      });
  };

  const refreshLinks = () => {
    api
      .getLinksForBusiness(match?.params.businessId!)
      .then(({ data: links }) => {
        if (Array.isArray(links)) {
          setLinks(links);
        }
      })
      .catch((err) => {
        console.error(`error getting business users for BusinessPage: ${err}`);
      });
  };

  const refreshBusiness = () => {
    api
      .getBusinessById(match?.params.businessId!)
      .then((response) => {
        const data = response.data;
        setBusiness(data);
        if (data?.complianceProfile) {
          setComplianceProfile(data.complianceProfile);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const refreshPlan = () => {
    api
      .getPlanForBusiness(match?.params.businessId!)
      .then(({ data: plan }) => {
        setPlan(plan);
      })
      .catch((err) => {
        console.error('error getting business plan for BusinessPage', err);
      });
  };

  const refreshPlanMetadata = () => {
    api
      .getPlanMetadataForBusiness(match?.params.businessId!)
      .then(({ data: metadata }) => {
        setPlanMetadata(metadata);
      })
      .catch((err) => {
        console.error('error getting business plan metadata for BusinessPage', err);
      });
  };

  const refreshCustomsOnboarding = () => {
    api
      .getCustomsOnboardingForBusiness(match?.params.businessId!)
      .then(({ data: customsOnboarding }) => {
        setCustomsOnboarding(customsOnboarding);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (match) {
      const businessId = match.params.businessId as string;
      refreshBusiness();
      refreshUsers();
      refreshLinks();
      refreshPlan();
      refreshPlanMetadata();
      refreshCustomsOnboarding();
      refreshShipments();
      refreshReports();
    }
  }, []);

  const refreshShipments = () => {
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getBusinessShipments(match?.params.businessId!)
      .then(function (response) {
        if (Array.isArray(response.data)) {
          const shipments: ShipmentWithDocumentIds[] = response.data;
          setShipments(shipments);
        }
        setLoadingStatus(LoadingStatus.SUCCESS);
      })
      .catch(function (error) {
        console.log(error);
        setLoadingStatus(LoadingStatus.ERROR);
      });
  };

  const handleCustomsOnboardedCheckboxClick = (e) => {
    if (e.target.checked && !isCustomsOnboarded()) {
      setLoadingStatus(LoadingStatus.LOADING);
      const completedCustomsOnboarding = { ...customsOnboarding, step: CustomsOnboardingSteps.COMPLETE };
      api
        .updateCustomsOnboarding(business?._id?.toString() as string, completedCustomsOnboarding)
        .then((response) => {
          setLoadingStatus(LoadingStatus.SUCCESS);
          refreshCustomsOnboarding();
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.ERROR);
        });
    }
  };

  const handleCancelEditBusiness = (cardName) => {
    setBusinessSummaryMode((prevMode) => ({
      ...prevMode,
      [cardName]: Mode.VIEW,
    }));
  };

  const handleUpdateBusiness = (cardName: CardName) => {
    if (cardName === 'businessInfo') {
      updateBusiness(business as BusinessWithDocuments, cardName);
    } else if (cardName === 'businessCompliance') {
      updateCompliance(complianceProfile, cardName);
    } else {
      console.error(`${cardName} does not exist`);
    }
  };

  const informationElement = (business: BusinessWithDocuments) => {
    return (
      <div>
        <Card className="my-shipments-card">
          <CardContent>
            <div className="my-shipments-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="shipment-summary-header-main-info">
                <div>Business Information</div>
              </div>
              {renderModeIconButton({
                setModeFunction: setBusinessSummaryMode,
                mode: businessSummaryMode.businessInfo,
                cardName: 'businessInfo',
                handleCancelEditBusiness,
                handleUpdateBusiness,
                handleChangeMode,
              })}
            </div>
            <div className="entry-info-container">
              <div className="entry-business-info-container">
                <div className="entry-business-info-row">
                  <div>
                    <div className="entry-row-header">Address</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessInfo,
                        FieldType.TEXT,
                        business.address,
                        (newValue: any) => handleBusinessInfoChange('address', newValue)
                      )}
                    </div>
                  </div>
                  {business.additionalState !== '' && (
                    <div>
                      <div className="entry-row-header">State of Incorporation</div>
                      <div className="entry-row-text">
                        {renderImportalField(
                          businessSummaryMode.businessInfo,
                          FieldType.TEXT,
                          business.additionalState,
                          (newValue: any) => handleBusinessInfoChange('additionalState', newValue)
                        )}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="entry-row-header">Type</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessInfo,
                        FieldType.TEXT,
                        business.businessStructure,
                        (newValue: any) => handleBusinessInfoChange('businessStructure', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">EIN/CAIN</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessInfo,
                        FieldType.TEXT,
                        business.ein,
                        (newValue: any) => handleBusinessInfoChange('ein', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">Payment Method to Customs</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessInfo,
                        FieldType.TEXT,
                        business.paymentMethodCustoms,
                        (newValue: any) => handleBusinessInfoChange('paymentMethodCustoms', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">Point of Contact</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessInfo,
                        FieldType.TEXT,
                        business.pointOfContact,
                        (newValue: any) => handleBusinessInfoChange('pointOfContact', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">Partners</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessInfo,
                        FieldType.TEXT,
                        business.partners,
                        (newValue: any) => handleBusinessInfoChange('partners', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">Bond Info</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessInfo,
                        FieldType.TEXT,
                        business.bondInfo,
                        (newValue: any) => handleBusinessInfoChange('bondInfo', newValue)
                      )}
                    </div>
                  </div>
                </div>
                <div className="entry-business-info-row">
                  <div>
                    <div className="entry-business-info-row-section-header">Services Overview</div>
                    <div className="entry-business-info-row">
                      <div>
                        <div className="entry-row-header">Bonds</div>
                        <div className="entry-row-text">
                          {renderImportalField(
                            businessSummaryMode.businessInfo,
                            FieldType.TEXT,
                            business.bonds,
                            (newValue: any) => handleBusinessInfoChange('bonds', newValue)
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="entry-row-header">ISF</div>
                        <div className="entry-row-text">
                          {renderImportalField(
                            businessSummaryMode.businessInfo,
                            FieldType.TEXT,
                            business.isf,
                            (newValue: any) => handleBusinessInfoChange('isf', newValue)
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="entry-row-header">Customs Clearance</div>
                        <div className="entry-row-text">
                          {renderImportalField(
                            businessSummaryMode.businessInfo,
                            FieldType.TEXT,
                            business.customsClearance,
                            (newValue: any) => handleBusinessInfoChange('customsClearance', newValue)
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="entry-row-header">Classifications</div>
                        <div className="entry-row-text">
                          {renderImportalField(
                            businessSummaryMode.businessInfo,
                            FieldType.TEXT,
                            business.classification,
                            (newValue: any) => handleBusinessInfoChange('classification', newValue)
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="entry-row-header">Disbursement</div>
                        <div className="entry-row-text">
                          {renderImportalField(
                            businessSummaryMode.businessInfo,
                            FieldType.TEXT,
                            business.disbursement,
                            (newValue: any) => handleBusinessInfoChange('disbursement', newValue)
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="my-shipments-card">
          <CardContent>
            <div className="my-shipments-header">
              <div className="my-shipments-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="shipment-summary-header-main-info">
                  <div>Compliance</div>
                </div>
                {renderModeIconButton({
                  setModeFunction: setBusinessSummaryMode,
                  mode: businessSummaryMode.businessCompliance,
                  cardName: 'businessCompliance',
                  handleCancelEditBusiness,
                  handleUpdateBusiness,
                  handleChangeMode,
                })}
              </div>
            </div>
            <div className="entry-info-container">
              <div className="entry-business-info-container">
                <div className="entry-business-info-row">
                  <div>
                    <div className="entry-row-header">Product Library</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessCompliance,
                        FieldType.CHECKBOX,
                        complianceProfile?.hasProductLibrary,
                        (newValue: any) => handleComplianceProfileChange('hasProductLibrary', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">Completed SOP?</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessCompliance,
                        FieldType.CHECKBOX,
                        complianceProfile?.standardOperatingProceduresComplete,
                        (newValue: any) =>
                          handleComplianceProfileChange('standardOperatingProceduresComplete', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">Payment Method to Customs</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessCompliance,
                        FieldType.TEXT,
                        complianceProfile?.paymentMethodCustoms,
                        (newValue: any) => handleComplianceProfileChange('paymentMethodCustoms', newValue)
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="entry-row-header">Point of Contact</div>
                    <div className="entry-row-text">
                      {renderImportalField(
                        businessSummaryMode.businessCompliance,
                        FieldType.TEXT,
                        complianceProfile?.pointOfContact,
                        (newValue: any) => handleComplianceProfileChange('pointOfContact', newValue)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="my-shipments-card">
          <CardContent>
            <div className="entry-card-header-container">
              <div className="my-shipments-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="shipment-summary-header-main-info">
                  <div>Billing</div>
                </div>
                {renderModeIconButton({
                  setModeFunction: setBusinessSummaryMode,
                  mode: businessSummaryMode.businessBilling,
                  cardName: 'businessBilling',
                  handleCancelEditBusiness,
                  handleUpdateBusiness,
                  handleChangeMode,
                })}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const isCustomsOnboarded = (): boolean => {
    return customsOnboarding?.step === CustomsOnboardingSteps.COMPLETE;
  };

  const refreshReports = async () => {
    try {
      const { data: reports } = await api.getReportsForBusiness(match?.params.businessId!);
      setReports(reports);
    } catch (err) {
      console.error('error getting reports for business');
      console.error(err);
    }
  };

  return (
    <>
      <Provider
        value={{
          plan,
          refreshPlan,
          planMetadata,
          refreshPlanMetadata,
          users,
          refreshUsers,
          business,
          refreshBusiness,
          entries,
          links,
          refreshLinks,
          customsOnboarding,
          refreshCustomsOnboarding,
          isCustomsOnboarded: isCustomsOnboarded(),
          reports,
          refreshReports,
        }}
      >
        <div className="product-library-header">
          <div className="business-header-text">
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '26px',
              }}
            >
              {business?.name || ''}
            </Typography>
            <div className="universal-subheader">
              View all relevant business and customs information for this customer
            </div>
          </div>
          <div className="title-question-body">
            <div className="business-customs-checkbox-container">
              Customs Onboarded?
              <Checkbox checked={isCustomsOnboarded()} onChange={handleCustomsOnboardedCheckboxClick} />
            </div>
          </div>
        </div>

        {business ? (
          <>
            <Box sx={{ width: '100%' }}>
              <ImportalTabs
                tabsConfig={[
                  { title: 'Information', element: informationElement(business) },
                  {
                    title: 'Shipments',
                    element: (
                      <>
                        <Card className="my-shipments-card">
                          <CardContent>
                            <div className="my-shipments-header">Shipments</div>
                            <div className="my-shipments-subheader">
                              {business.name} has {shipments.length} active shipments in-transit.
                            </div>
                            <div className="shipments-table-container">
                              <ShipmentsTable rows={shipments} isLoading={false}></ShipmentsTable>
                            </div>
                          </CardContent>
                        </Card>
                      </>
                    ),
                  },
                  {
                    title: 'Products',
                    element: (
                      <>
                        <Card className="my-shipments-card">
                          <CardContent>
                            <div className="entry-card-header-container">
                              <div className="my-shipments-header">Products</div>
                            </div>
                          </CardContent>
                        </Card>
                      </>
                    ),
                  },
                  {
                    title: 'Documents',
                    element: (
                      <>
                        <Card className="my-shipments-card">
                          <CardContent>
                            <ManageDocuments
                              filter={businessDocumentsFilter}
                              useDeleteConfirmationModal={true}
                              fontSize={'24px'}
                              documents={documents}
                              setDocuments={setDocuments}
                              onDocumentsUpdated={saveDocumentsToBusiness}
                              parentName={'business'}
                            />
                          </CardContent>
                        </Card>
                      </>
                    ),
                  },
                  {
                    title: 'Reporting',
                    element: <BusinessReportingTab />,
                  },
                  {
                    title: 'HTS Classifications',
                    element: <ClassificationRequestsTab />,
                  },
                  {
                    title: 'Admin',
                    element: (
                      <>
                        <BusinessAdminTab />
                      </>
                    ),
                  },
                ]}
              />
            </Box>
          </>
        ) : (
          <span>Loading...</span>
        )}
        <DataImportLoader
          loadingState={{ loadingStatus }}
          loadingText={'Getting business info...'}
          successText={'Successfully retrieved business info'}
          errorText={'Error getting business info'}
        />
      </Provider>
    </>
  );
}
