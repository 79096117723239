import { Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './ImportalStepper.css';

export type Steps = Array<{
  label: string;
  icon?: React.ReactElement;
}>;

interface Props {
  steps: Steps;
  activeStep: any;
  setActiveStep: (arg0: number) => void;
  completed: any;
}

export const ImportalStepper = ({ steps, activeStep, setActiveStep, completed }: Props) => {
  interface ExtendedStepIconProps extends StepIconProps {
    index: number; // Adding index field
  }

  function ColorlibStepIcon(props: ExtendedStepIconProps) {
    const { index, active, completed, className, icon } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <CheckIcon /> : props.icon || index + 1}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
      fontWeight: 600,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient( 95deg,rgb(56,142,60) 0%,rgb(56,142,60) 50%,rgb(56,142,60) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient( 95deg,rgb(56,142,60) 0%,rgb(56,142,60) 50%,rgb(56,142,60) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? 'blue' : '#eaeaf0',
      borderRadius: 1,
      width: '100%',
    },

    '@media (max-width: 600px)': {
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 16,
      },
      [`& .${stepConnectorClasses.line}`]: {
        width: '100%',
      },
    },
  }));

  const handleStepClick = (index) => {
    if (index in completed) {
      setActiveStep(index);
    }
  };

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'blue' : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage: 'linear-gradient( 136deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      fontWeight: '700',
    }),
    ...(ownerState.completed && {
      backgroundImage: 'linear-gradient( 136deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)',
    }),
    '@media (max-width: 600px)': {
      width: 35,
      height: 35,
    },
  }));

  return (
    <Stepper
      alternativeLabel
      sx={{
        marginBottom: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
      connector={<ColorlibConnector />}
      nonLinear
      activeStep={activeStep}
    >
      {steps.map((step, index) => (
        <Step
          key={step.label}
          onClick={() => handleStepClick(index)}
          sx={{ cursor: 'pointer' }}
          completed={completed[index]}
        >
          <StepLabel StepIconComponent={(props) => <ColorlibStepIcon {...props} index={index} icon={step.icon} />}>
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
