import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  Autocomplete,
  Badge,
  BadgeProps,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { Search, Settings } from '@mui/icons-material';
import { styled } from '@mui/system';
import {
  createTableFilterFunction,
  createTableSortFunction,
  FilterFunction,
  noFilter,
  noSort,
  SortFunction,
} from 'common/utilities/importalTable';
import { ColumnConfig, ColumnSortConfig, SortDirection, TableConfig } from 'common/interfaces/importalTable';
import './ImportalTable.css';
import ImportalTableSettingsModal from '../ImportalTableSettingsModal/ImportalTableSettingsModal';
import HoverState from '../HoverState';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalButtonGroup from '../ImportalButtonGroup/ImportalButtonGroup';

const SortOrderBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    padding: '4px',
    transform: 'translate(0px, 0px) scale(0.7)',
  },
}));

interface Props<T> {
  data: Array<T>;
  rowGenerator?: (rowData: T, index: number) => React.JSX.Element;
  tableConfig: TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
  topRightElement?: React.JSX.Element;
  embeddedStyling?: boolean;
  loading?: boolean;
  defaultRowsPerPage?: number;
  hasRowExpand?: boolean;
  topRightButton?: React.JSX.Element;
  useSearch?: boolean;
  useSettings?: boolean;
  predefinedViews?: TableConfig[];
}

export interface ImportalTableHandles {
  getTableConfig: () => TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
}

const DefaultRowGenerator = (rowData: any, index: number, columnConfig: ColumnConfig[]) => {
  return (
    <TableRow key={`default-row-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell />
      {columnConfig
        .filter((config) => config.isVisible !== false)
        .map((config) => (
          <TableCell key={config.name}>{rowData[config.name]}</TableCell>
        ))}
    </TableRow>
  );
};

const ImportalTable = forwardRef<ImportalTableHandles, Props<any>>(
  (
    {
      data = [],
      rowGenerator,
      tableConfig,
      setTableConfig,
      topRightElement,
      embeddedStyling = false,
      loading = false,
      defaultRowsPerPage = 20,
      hasRowExpand = true,
      topRightButton,
      useSearch = true,
      useSettings = false,
      predefinedViews = [],
    },
    ref
  ) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [filterFunction, setFilterFunction] = useState<FilterFunction>(() => noFilter);
    const [sortFunction, setSortFunction] = useState<SortFunction>(() => noSort);
    const [sortedAndFilteredData, setSortedAndFilteredData] = useState<any[]>([]);
    const [tableSettingsModalOpen, setTableSettingsModalOpen] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState<ColumnConfig | null>(null);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [viewCounts, setViewCounts] = useState<Record<string, number>>({});

    const handleTableSettingsModalOpen = () => {
      setTableSettingsModalOpen(true);
    };

    const handleTableSettingsModalClose = () => {
      setTableSettingsModalOpen(false);
    };

    useImperativeHandle(ref, () => ({
      getTableConfig() {
        return tableConfig;
      },
      setTableConfig(tableConfig: TableConfig) {
        setTableConfig(tableConfig);
      },
    }));

    useEffect(() => {
      if (data.length > 0) {
        const counts = countDataForViews(predefinedViews, data, tableConfig.subKeyForData);
        setViewCounts(counts);
      }
    }, [data, predefinedViews, tableConfig.subKeyForData]);

    useEffect(() => {
      setFilterFunction(() => createTableFilterFunction(tableConfig.filterConfig, tableConfig.subKeyForData));
    }, [tableConfig.filterConfig, tableConfig.subKeyForData]);

    useEffect(() => {
      setSortFunction(() =>
        createTableSortFunction(tableConfig.columns, tableConfig.sortConfig, tableConfig.subKeyForData)
      );
    }, [tableConfig.sortConfig, tableConfig.columns, tableConfig.subKeyForData]);

    useEffect(() => {
      if (data && data.length > 0) {
        let filteredData = data.filter(filterFunction).sort(sortFunction);

        if (searchQuery) {
          filteredData = filteredData.filter((row) =>
            Object.values(tableConfig.subKeyForData ? row[tableConfig.subKeyForData] : row).some((value) =>
              String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
          );
        }

        setSortedAndFilteredData(filteredData);
      }
    }, [data, sortFunction, filterFunction, searchQuery]);

    const countDataForViews = (
      predefinedViews: TableConfig[],
      data: any[],
      subKeyForData?: string
    ): Record<string, number> => {
      const viewCounts: Record<string, number> = {};

      predefinedViews.forEach((view) => {
        const filterFunction = createTableFilterFunction(view.filterConfig, subKeyForData);
        const filteredData = data.filter(filterFunction);
        viewCounts[view.viewName as string] = filteredData.length;
      });

      return viewCounts;
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
      setPage(0); // Reset to first page when search query changes
    };

    const handleChangePage = useMemo(
      () => (event: unknown, newPage: number) => {
        setPage(newPage);
      },
      [setPage]
    );

    const handleChangeRowsPerPage = useMemo(
      () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      },
      [setRowsPerPage, setPage]
    );

    const getUniqueValuesForColumn = (column: ColumnConfig): string[] => {
      const columnValues = sortedAndFilteredData.map((row) => {
        const rowData = tableConfig.subKeyForData ? row[tableConfig.subKeyForData] : row;
        return String(rowData[column.name] || '');
      });

      return Array.from(new Set(columnValues)).filter((value) => {
        return value.trim() !== '' && !tableConfig.filterConfig[column.name]?.allowedValues?.includes(value);
      });
    };

    const handleSelectedFilterColumnChange = (event: React.ChangeEvent<{}>, selectedCol: ColumnConfig | null) => {
      if (selectedCol) {
        setSelectedColumn(selectedCol);
      } else {
        // If the first Autocomplete is cleared, also clear the second Autocomplete
        setSelectedColumn(null);
        setSelectedValue(null); // Here is where we clear the second Autocomplete
      }
    };

    const handleViewClick = (view: TableConfig, e?: React.MouseEvent<HTMLElement>) => {
      setTableConfig(view);
    };

    const handleFilterValueAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if ((event.key === 'Tab' || event.code === 'Enter') && selectedColumn && selectedValue) {
        const newFilterConfig = {
          ...tableConfig.filterConfig,
          [selectedColumn.name]: {
            allowedValues: [selectedValue],
            columnName: selectedColumn.name,
            displayName: selectedColumn.displayName,
          },
        };
        setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });

        handleAddFilterChip(selectedColumn.name, selectedColumn.displayName as string, selectedValue);

        setSelectedColumn(null);
        setSelectedValue(null);
      }
    };

    const handleAddFilterChip = (columnName: string, displayName: string, value: string) => {
      const newFilterConfig = { ...tableConfig.filterConfig };

      if (!newFilterConfig[columnName]) {
        newFilterConfig[columnName] = {
          allowedValues: [value],
          columnName: columnName,
          displayName: displayName,
        };
      } else {
        const existingValues = newFilterConfig[columnName].allowedValues;
        if (!existingValues.includes(value)) {
          newFilterConfig[columnName] = {
            ...newFilterConfig[columnName],
            allowedValues: [...existingValues, value],
          };
        }
      }

      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
    };

    const handleValueChange = (event: React.SyntheticEvent, value: string | null) => {
      if (!event) return;
      const isTabKey = (event as React.KeyboardEvent).key === 'Tab';
      setSelectedValue(value);

      if (isTabKey && selectedColumn && value) {
        const newFilterConfig = {
          ...tableConfig.filterConfig,
          [selectedColumn.name]: {
            allowedValues: [value],
            columnName: selectedColumn.name,
            displayName: selectedColumn.displayName,
          },
        };
        setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });

        handleAddFilterChip(selectedColumn.name, selectedColumn.displayName as string, value);
      }
    };

    // Function to remove a chip and its associated filter
    const handleDeleteFilterChip = (columnName: string, value: string) => {
      const newFilterConfig = { ...tableConfig.filterConfig };

      // Filter out the specific value from allowedValues
      const filteredValues = newFilterConfig[columnName].allowedValues.filter((val) => val !== value);

      if (filteredValues.length === 0) {
        // Remove the filter entirely if no values are left
        delete newFilterConfig[columnName];
      } else {
        // Update the allowedValues with the remaining ones
        newFilterConfig[columnName] = {
          ...newFilterConfig[columnName],
          allowedValues: filteredValues,
        };
      }

      // Set the updated filterConfig in tableConfig
      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
    };

    const onClickColumnSort = (columnName: string) => {
      const matchesName = (sortConfig: ColumnSortConfig) => sortConfig.columnName === columnName;
      const newTableSortConfig = [...tableConfig.sortConfig];

      const currentSortDirection = tableConfig.sortConfig?.find(matchesName);
      if (currentSortDirection && currentSortDirection.direction === SortDirection.asc) {
        // set to desc
        newTableSortConfig[newTableSortConfig.findIndex(matchesName)]['direction'] = SortDirection.desc;
      } else if (currentSortDirection && currentSortDirection.direction === SortDirection.desc) {
        // remove entirely
        newTableSortConfig.splice(newTableSortConfig.findIndex(matchesName), 1);
      } else {
        // set to asc
        newTableSortConfig.push({
          columnName: columnName,
          displayName: tableConfig.columns.find((config: ColumnConfig) => config.name === columnName)?.displayName,
          direction: SortDirection.asc,
        });
      }

      setTableConfig({ ...tableConfig, sortConfig: newTableSortConfig });
    };

    const getTableSortLabel = (columnConfig: ColumnConfig): React.JSX.Element => {
      return (
        <TableSortLabel
          active={isSorted(columnConfig.name)}
          direction={getColumnSortDirection(columnConfig.name)}
          onClick={() => onClickColumnSort(columnConfig.name)}
        >
          {columnConfig.displayName || columnConfig.name}
        </TableSortLabel>
      );
    };

    const isSorted = (columnName: string): boolean => {
      return -1 !== tableConfig.sortConfig.findIndex((columnSortConfig) => columnSortConfig.columnName === columnName);
    };

    const getColumnSortDirection = (columnName: string): SortDirection | undefined => {
      return tableConfig.sortConfig.find((columnSortConfig) => columnSortConfig.columnName === columnName)?.direction;
    };

    const getSortOrderForColumn = (columnName: string): number | undefined => {
      const foundIndex = tableConfig.sortConfig.findIndex(
        (columnSortConfig) => columnSortConfig.columnName === columnName
      );
      return foundIndex !== -1 ? foundIndex + 1 : undefined;
    };

    const headerCellStyle = {
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontWeight: '600',
    };

    return (
      <div className="importal-table-and-search-container">
        {predefinedViews.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <ImportalButtonGroup style={{ height: '40px' }}>
                {predefinedViews.map((view, index) => (
                  <ImportalPrimaryButton
                    key={`one-${index}`}
                    style={{
                      fontSize: '12px',
                      padding: '0px 12px 0px 12px',
                      boxShadow: 'none',
                      fontWeight: tableConfig === view ? 'bold' : 'normal',
                      borderLeft: '1px solid #A9A9A9',
                    }}
                    text={`${view.viewName || ''} (${viewCounts[view.viewName as string] || 0})`}
                    onClick={(e) => handleViewClick(view, e)}
                  />
                ))}
              </ImportalButtonGroup>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '6px', alignItems: 'end', marginTop: '8px' }}>
              <Paper
                sx={{
                  borderRadius: '8px!important',
                  minWidth: '225px',
                  height: '40px',
                  paddingLeft: '10px',
                  paddingRight: '16px',
                }}
              >
                <Autocomplete
                  options={tableConfig.columns}
                  getOptionLabel={(option: ColumnConfig | string) => {
                    if (typeof option === 'string') return option;
                    return option.displayName || option.name;
                  }}
                  value={selectedColumn}
                  onChange={handleSelectedFilterColumnChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Filter by column..."
                      fullWidth={true}
                      sx={{
                        backgroundColor: 'transparent',
                        '& .MuiOutlinedInput-root': {
                          padding: '4px',
                          paddingLeft: '8px',
                          '& fieldset': {
                            border: '1px solid lightgrey',
                            borderRadius: '8px',
                          },
                          fontSize: '12px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '12px',
                        },
                      }}
                    />
                  )}
                />
              </Paper>

              {selectedColumn && selectedColumn.name && (
                <Paper
                  sx={{
                    borderRadius: '8px!important',
                    minWidth: '250px',
                    height: '40px',
                    paddingLeft: '10px',
                    paddingRight: '16px',
                  }}
                >
                  <Autocomplete
                    options={getUniqueValuesForColumn(selectedColumn)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`Select a value for ${selectedColumn.displayName || selectedColumn?.name}`}
                        fullWidth={true}
                        onKeyDown={handleFilterValueAutocompleteKeyDown} // Handle "Tab" press for filter
                        sx={{
                          backgroundColor: 'transparent',
                          '& .MuiOutlinedInput-root': {
                            padding: '4px',
                            paddingLeft: '8px',
                            '& fieldset': {
                              border: '1px solid lightgrey',
                              borderRadius: '8px',
                            },
                            fontSize: '12px',
                          },
                          '& .MuiInputBase-input': {
                            fontSize: '12px',
                          },
                        }}
                      />
                    )}
                    inputValue={selectedValue || ''} // Make sure the value reflects the state of clearing
                    onInputChange={(event, newInputValue) => handleValueChange(event, newInputValue)}
                  />
                </Paper>
              )}
              <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'end', gap: '8px' }}>
                {Object.keys(tableConfig.filterConfig).map((columnName) => {
                  const filterConfig = tableConfig.filterConfig[columnName];
                  const defaultFilter =
                    predefinedViews.find((view) => view === tableConfig)?.filterConfig[columnName]?.allowedValues || [];

                  // Only show the chip if the current filter values differ from the default values
                  return filterConfig.allowedValues
                    .filter((value) => !defaultFilter.includes(value))
                    .map((value) => (
                      <Chip
                        key={`${columnName}-${value}`}
                        label={`${filterConfig.displayName || filterConfig.columnName}: ${value}`}
                        onDelete={() => handleDeleteFilterChip(columnName, value)}
                        sx={{
                          backgroundColor: '#77A8F6',
                          color: 'white',
                          borderRadius: '10px!important',
                          fontWeight: '700',
                          padding: '8px',
                          fontSize: '12px',
                          '& .MuiChip-deleteIcon': {
                            color: 'white',
                            fontSize: '18px',
                          },
                        }}
                      />
                    ));
                })}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'end' }}>
            {useSettings && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{
                    width: '36px',
                    height: '36px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={handleTableSettingsModalOpen}
                >
                  <Settings sx={{ fontSize: '20px' }} />
                </IconButton>
              </div>
            )}
            {useSearch && (
              <Paper
                sx={{
                  minWidth: '300px',
                  borderRadius: '8px!important',
                  height: '40px',
                  paddingLeft: '10px',
                }}
              >
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  placeholder="Search..."
                  sx={{ height: '40px!important', input: { fontSize: 12 } }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ fontSize: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            )}
            {topRightButton}
          </div>
        </div>
        <Paper sx={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '4px' }}>
          <TableContainer className="importal-table-container">
            <div style={{ overflowX: 'scroll', maxWidth: '100%' }}>
              <Table className="importal-table">
                <TableHead className="importal-table-header">
                  <TableRow sx={{ borderRadius: '8px' }} className="importal-table-header-row">
                    {hasRowExpand && <TableCell className="importal-table-header-row-cell" id="expand-spacer" />}
                    {tableConfig.columns
                      .filter((fieldConfig) => fieldConfig.isVisible !== false)
                      .map((fieldConfig, index) => (
                        <HoverState key={`field-${index}`}>
                          {(isHovered, bindHover) => (
                            <TableCell
                              key={index}
                              sx={headerCellStyle}
                              className={`importal-table-header-row-cell ${fieldConfig.sortable && 'sorting'} ${
                                fieldConfig.filterable && 'filtering'
                              }`}
                              {...bindHover}
                            >
                              {fieldConfig.sortable ? (
                                <SortOrderBadge badgeContent={getSortOrderForColumn(fieldConfig.name)} color="primary">
                                  {getTableSortLabel(fieldConfig)}
                                </SortOrderBadge>
                              ) : (
                                fieldConfig.displayName || fieldConfig.name
                              )}
                            </TableCell>
                          )}
                        </HoverState>
                      ))}
                    {topRightElement && (
                      <TableCell
                        className="importal-table-header-row-cell"
                        sx={headerCellStyle}
                        style={{ textAlign: 'center' }}
                      >
                        {topRightElement}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedAndFilteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((datum, index) => (rowGenerator || DefaultRowGenerator)(datum, index, tableConfig.columns))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[5, 10, 20, 50]}
              count={sortedAndFilteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ImportalTableSettingsModal
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              open={tableSettingsModalOpen}
              onClose={handleTableSettingsModalClose}
              onTableSettingsEdited={() => {}}
              tableData={sortedAndFilteredData}
              predefinedViews={predefinedViews}
            />
          </TableContainer>
        </Paper>
      </div>
    );
  }
);

export default ImportalTable;
