import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { useAPI } from '@/api/APIContext';
import { useLocation, useNavigate } from 'react-router';
import { AxiosResponse } from 'axios';
import { Document } from 'common/interfaces/document';
import { datadogRum } from '@datadog/browser-rum';

function ViewDocumentPage() {
  const api = useAPI();
  const [src, setSrc] = useState<string>();
  const location = useLocation();
  const [document, setDocument] = useState({} as Document);
  const [fileTypeSupported, setFileTypeSupported] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const supportedFileTypes = [{ extension: 'pdf', type: 'application/pdf' }];

  const determineFileType = (document: Document) => {
    const fileExtension = document.fileName?.split('.').pop()?.toLowerCase();
    const fileType = supportedFileTypes.find((ft) => ft.extension === fileExtension);

    if (fileType) {
      setFileTypeSupported(true);
      return fileType;
    } else {
      setFileTypeSupported(false);
      return null;
    }
  };

  const fetchDocumentData = async (documentId: string) => {
    try {
      const documentResponse: AxiosResponse = await api.getDocument(documentId);
      const documentDataResponse: AxiosResponse = await api.getDocumentData(documentId);
      const fileType = determineFileType(documentResponse.data);
      if (fileType) {
        switch (fileType.extension) {
          case 'pdf':
            const blob = new Blob([documentDataResponse.data], { type: fileType.type });
            const url = URL.createObjectURL(blob);
            setSrc(url);
            break;
          default:
            break;
        }
      } else {
        const domDocument = window.document;
        const link = domDocument.createElement('a');
        link.href = api.getDocumentURL(documentResponse.data);
        domDocument.body.appendChild(link);
        datadogRum.addAction('documentDownload', {});
        link.click();
        domDocument.body.removeChild(link);
      }

      setDocument(documentResponse.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const documentId = pathParts[pathParts.length - 1];
    if (!documentId) return;
    fetchDocumentData(documentId);
  }, [location]);

  return (
    <Card
      sx={{
        padding: '16px',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {fileTypeSupported === true ? (
        <iframe src={src} title="View Document Page" style={{ width: '100%', height: '100%' }} />
      ) : fileTypeSupported === false ? (
        <div className="no-results-container">
          Boom! Your document should download instantly. If you have any issues,
          <a style={{ marginLeft: '3px', marginRight: '3px' }} href={api.getDocumentURL(document)}>
            click here
          </a>
          to manually download it.
        </div>
      ) : (
        <div className="no-results-container">Loading document information, please wait...</div>
      )}
    </Card>
  );
}

export default ViewDocumentPage;
