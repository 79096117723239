import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router';
import { IconButton, useTheme } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeComponent from '@/broker-app/pages/home-component/HomeComponent';
import Drawer from '@mui/material/Drawer';
import Settings from '@/pages/settings/Settings';
import NavMenu, { NavItem } from '@/shared-components/navMenu';
import './Dashboard.css';
import DashboardContext from './DashboardProvider';
import ComplianceOne from '@/pages/compliance-one/ComplianceOne';
import DutyCalculator from '@/pages/duty-calculator/DutyCalculator';
import { Route, Routes } from 'react-router-dom';
import Shipments from '@/broker-app/pages/shipments/Shipments';
import Businesses from '../businesses/Businesses';
import { AppContext } from '@/App';
import BetaFeatureRoute from '@/custom-hooks/beta-feature/BetaFeatureRoute';
import NotFoundPage from '@/pages/not-found-page/NotFoundPage';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import ConstructionIcon from '@mui/icons-material/Construction';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CalculateIcon from '@mui/icons-material/Calculate';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessIcon from '@mui/icons-material/Business';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';
import BetaFeaturesControlPanel from '@/broker-app/pages/beta-features/BetaFeaturesControlPanel';
import { Biotech, Mouse, SoupKitchen, ToggleOff, ToggleOn } from '@mui/icons-material';
import { BrokerClassifications } from '@/broker-app/pages/hts-classifications/BrokerClassifications';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SideMenu from '@/shared-components/SideMenu/SideMenu';

export default function BrokerDashboard(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const match = useMatch({
    path: '/broker/dashboard/:subRoute',
  });

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
    document.documentElement.style.setProperty('--left-menu-width-collapsed', isExpanded ? '88px' : '276px');
  };

  const theme = useTheme();
  const betaFeatures = useBetaFeatures();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState<string>('home');
  const [navSelection, setNavSelection] = useState<NavItem | undefined>();
  const { clearSession } = useContext(AppContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    clearSession();
    navigate('/login');
  };

  const navItems: NavItem[] = [
    {
      displayName: 'Home',
      routerPath: '',
      icon: <HomeIcon />,
      element: <HomeComponent />,
    },
    {
      displayName: 'Shipments',
      routerPath: 'shipments/*',
      icon: <DirectionsBoatIcon />,
      element: <Shipments />,
    },
    {
      displayName: 'Businesses',
      routerPath: 'businesses/*',
      icon: <BusinessIcon />,
      element: <Businesses />,
    },
    {
      displayName: 'HTS Classifications',
      routerPath: 'hts-classifications/*',
      icon: <SummarizeIcon />,
      element: <BrokerClassifications />,
    },
    {
      displayName: 'Compliance Tools',
      routerPath: 'compliance-tools',
      icon: <ConstructionIcon />,
      element: undefined,
      children: [
        {
          displayName: 'Compliance One',
          routerPath: 'compliance-one',
          icon: <FactCheckIcon />,
          element: <ComplianceOne />,
        },
        {
          displayName: 'Duty Calculator',
          routerPath: 'duty-calculator',
          icon: <CalculateIcon />,
          element: <DutyCalculator />,
        },
      ],
    },
    {
      displayName: 'Beta Features',
      routerPath: 'beta-features',
      icon: <Biotech />,
      element: <BetaFeaturesControlPanel />,
      betaFeature: 'beta-features-control-panel',
    },
    {
      displayName: 'Settings',
      routerPath: 'settings',
      icon: <SettingsIcon />,
      element: <Settings setSelectedModule={setNavSelection} />,
    },
    {
      displayName: 'Consumer App',
      routerPath: 'dashboard',
      icon: <Mouse />,
      onClick: () => {
        navigate('/dashboard', { relative: 'path' });
      },
    },
    {
      displayName: 'Logout',
      routerPath: 'logout',
      icon: <LogoutIcon />,
      element: undefined,
      onClick: () => handleLogout(),
    },
  ];

  useEffect(() => {
    const selectedRoute = match?.params.subRoute;

    function findSelectedNavItem(
      localNavItems: NavItem[] | undefined,
      localSelectedRoute?: string
    ): NavItem | undefined {
      if (!localNavItems || localNavItems.length === 0) {
        return undefined;
      }
      for (const navItem of localNavItems) {
        if (!navItem.routerPath && !localSelectedRoute) {
          // this is for home component which has no route beyond dashboard
          return navItem;
        }

        const routerPathSansWildCard = navItem.routerPath.endsWith('/*')
          ? navItem.routerPath.slice(0, -2)
          : navItem.routerPath;
        if (routerPathSansWildCard === localSelectedRoute) {
          return navItem;
        }
        const matchingChild = findSelectedNavItem(navItem.children, localSelectedRoute);
        if (matchingChild) {
          return matchingChild;
        }
      }
    }

    const preSelectedNavItem = findSelectedNavItem(navItems, selectedRoute);

    setNavSelection(preSelectedNavItem!);
  }, [location]);

  const shouldShowNavItem = (navItem: NavItem): boolean => {
    if (navItem.betaFeature) {
      return betaFeatures.includes(navItem.betaFeature);
    } else {
      return true;
    }
  };

  const renderNavItemAsRoute = (navItem: NavItem, index: number) => {
    if (navItem.children) {
      return navItem.children?.map(renderNavItemAsRoute);
    } else if (navItem.betaFeature) {
      return (
        <Route
          key={index}
          path={navItem.routerPath}
          element={
            <BetaFeatureRoute betaFeature={navItem.betaFeature} fallback={<NotFoundPage />}>
              {navItem.element}
            </BetaFeatureRoute>
          }
        />
      );
    } else {
      return <Route key={index} path={navItem.routerPath} element={navItem.element} />;
    }
  };

  return (
    <div>
      <div className="dashboard-container">
        <Drawer anchor={open ? 'left' : undefined} open={showSideMenu} onClose={() => setShowSideMenu(false)}>
          <div className="left-menu">
            <div className="importal-logo-dashboard">
              <img src="/IMPORTAL.png" width="185" height="120" alt="logo" />
            </div>
            <NavMenu
              navItems={navItems.filter(shouldShowNavItem)}
              open={open}
              isMenuOpen={isExpanded}
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              onNavItemSelected={(navItem) => {
                const routerPathSansWildCard = navItem.routerPath.endsWith('/*')
                  ? navItem.routerPath.slice(0, -2)
                  : navItem.routerPath;
                setNavSelection(navItem);
                navigate(routerPathSansWildCard);
              }}
              selectedNavItem={navSelection?.displayName}
              isBrokerApp={true}
            />
          </div>
        </Drawer>
        <SideMenu
          isExpanded={isExpanded}
          navItems={navItems}
          shouldShowNavItem={shouldShowNavItem}
          open={open}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          setNavSelection={setNavSelection}
          navigate={navigate}
          navSelection={navSelection}
          toggleMenu={toggleMenu}
          isBrokerApp={true}
        />
        <div className="broker-dashboard-content">
          <DashboardContext.Provider
            value={{
              selectedModule,
              setSelectedModule,
            }}
          >
            <Routes>{navItems.map(renderNavItemAsRoute)}</Routes>
          </DashboardContext.Provider>
        </div>
      </div>
    </div>
  );
}
