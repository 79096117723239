import { Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { CommercialInvoice, CommercialInvoiceLineItem } from 'common/interfaces/commercialInvoice';
import { useAPI } from '@/api/APIContext';
import ImportalCard from '@/shared-components/ImportalCard';
import ManageItems, { HeaderConfig } from '@/shared-components/ManageItems/ManageItems';

interface Props {
  shipmentId: string;
}

export default function CommercialInvoiceTab({ shipmentId }: Props) {
  const api = useAPI();
  const [commercialInvoices, setCommercialInvoices] = useState<CommercialInvoice[]>([]);

  const getDefaultCommercialInvoiceLineItem = (): CommercialInvoiceLineItem => ({
    poNumber: '',
    sku: '',
    description: '',
    materialContent: '',
    unitQuantity: '',
    unitValue: '',
    currencyCode: '',
    totalValue: '',
    countryOfOrigin: '',
    additionalProperties: {},
    containerNumber: '',
  });

  const manageCommercialInvoiceLineItemsConfig: HeaderConfig[] = [
    {
      header: 'PO #',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.poNumber}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.poNumber}
          onChange={(e) => setItem({ ...item, poNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Container #',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.containerNumber}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.containerNumber}
          onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'SKU',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.sku}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.sku}
          onChange={(e) => setItem({ ...item, sku: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Description',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.description}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.description}
          onChange={(e) => setItem({ ...item, description: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Material Content',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.materialContent}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.materialContent}
          onChange={(e) => setItem({ ...item, materialContent: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Quantity',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitQuantity}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.unitQuantity}
          onChange={(e) => setItem({ ...item, unitQuantity: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Unit Value',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitValue}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.unitValue}
          onChange={(e) => setItem({ ...item, unitValue: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Total Value',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.totalValue}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.totalValue}
          onChange={(e) => setItem({ ...item, totalValue: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'COO',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.countryOfOrigin}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.countryOfOrigin}
          onChange={(e) => setItem({ ...item, countryOfOrigin: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
  ];

  const commercialInvoiceLineItems: CommercialInvoiceLineItem[] = [
    {
      poNumber: 'PO12345',
      sku: 'SKU123',
      description: 'Laptop',
      materialContent: 'Aluminum',
      unitQuantity: '10',
      unitValue: '1000',
      currencyCode: 'USD',
      totalValue: '10000',
      countryOfOrigin: 'USA',
      additionalProperties: {
        warranty: '1 year',
        color: 'Silver',
      },
      containerNumber: 'CONT001',
    },
    {
      poNumber: 'PO54321',
      sku: 'SKU456',
      description: 'Smartphone',
      materialContent: 'Plastic',
      unitQuantity: '50',
      unitValue: '500',
      currencyCode: 'USD',
      totalValue: '25000',
      countryOfOrigin: 'China',
      additionalProperties: {
        warranty: '2 years',
        batteryLife: '24 hours',
      },
      containerNumber: 'CONT002',
    },
    {
      poNumber: 'PO67890',
      sku: 'SKU789',
      description: 'Headphones',
      materialContent: 'Leather',
      unitQuantity: '100',
      unitValue: '100',
      currencyCode: 'USD',
      totalValue: '10000',
      countryOfOrigin: 'Germany',
      additionalProperties: {
        warranty: '6 months',
        noiseCancelling: 'Yes',
      },
      containerNumber: 'CONT003',
    },
  ];

  const commercialInvoiceLineItemsStyles = {
    headerCellStyles: {
      backgroundColor: 'lightgray',
      padding: '8px!important',
      marginRight: '2px',
      fontSize: '12px!important',
      fontWeight: '700',
      color: 'black',
    },
    icon: {
      fontSize: '14px',
    },
    itemsTableContainer: {
      marginTop: '16px',
      '& .MuiTableCell-root': {
        padding: '4px!important',
      },
    },
    itemsTableRow: {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  };

  useEffect(() => {
    api
      .getCommercialInvoicesForShipment(shipmentId)
      .then(({ data }) => {
        setCommercialInvoices(data);
      })
      .catch((err) => {
        console.error('error getting commercial invoices for shipment');
        console.error(err);
      });
  }, [shipmentId, api]);

  const styles = {
    commercialInvoiceContainer: {
      display: 'flex',
      width: '100%',
    },
    viewColumn: {
      flex: 1,
    },
    parsedViewContainer: {
      alignItems: 'center',
      boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
      borderRadius: '8px',
      color: '#525256',
      padding: '24px',
    },
    parsedViewHeader: {
      marginBottom: '2px',
      marginTop: '0px',
      color: 'black',
      display: 'flex',
      justifyContent: 'center',
    },
    parsedViewSubheader: {
      fontSize: '12px',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '24px',
    },
    parsedViewSummarySection: {
      display: 'flex',
      width: '100%',
      fontSize: '12px',
      marginTop: '16px',
      gap: '24px',
    },
    parsedViewSummarySectionColumn: {
      flex: 1,
    },
    parsedViewSummarySectionColumnHeader: {
      fontWeight: 'bold',
      color: 'black',
    },
    parsedViewLineItemSection: {
      width: '100%',
    },
  };

  return (
    <Container maxWidth={'xl'}>
      <ImportalCard title="Commercial Invoices">
        <div style={styles.commercialInvoiceContainer}>
          <div style={styles.viewColumn}>
            <h3>Original Document</h3>
          </div>
          <div style={styles.viewColumn}>
            <h3>Parsed View</h3>
            <div style={styles.parsedViewContainer}>
              <div style={{ fontSize: '12px', color: 'black', gap: '8px', marginBottom: '24px' }}>
                <div>
                  <b>Invoice Number</b>
                </div>
                <div style={{ color: '#525256' }}>FP123456</div>
                <div>
                  <b>Invoice Date</b>
                </div>
                <div style={{ color: '#525256' }}>12/31/2021</div>
              </div>
              <h4 style={styles.parsedViewHeader}>Commercial Invoice</h4>
              <div style={styles.parsedViewSubheader}>
                Foot Accessory Manufacturing Inc, 1234 Factory Street, Shenzhen, China
              </div>
              <div style={styles.parsedViewSummarySection}>
                <div style={styles.parsedViewSummarySectionColumn}>
                  <div style={styles.parsedViewSummarySectionColumnHeader}>Importer of Record</div>
                  <div>Sock Importing Company, Inc. 5678 Import Blvd Los Angeles, CA 95678</div>
                </div>
                <div style={styles.parsedViewSummarySectionColumn}>
                  <div style={styles.parsedViewSummarySectionColumnHeader}>Supplier Information</div>
                  <div>Sock Supplier, Inc. 5678 Import Blvd Los Angeles, CA 95678</div>
                </div>
                <div style={styles.parsedViewSummarySectionColumn}>
                  <div style={styles.parsedViewSummarySectionColumnHeader}>Manufacturer Information</div>
                  <div>Sock Manufacturer Company, Inc. 5678 Import Blvd Los Angeles, CA 95678</div>
                </div>
              </div>
              <div style={styles.parsedViewLineItemSection}>
                <ManageItems
                  manageItemsConfig={manageCommercialInvoiceLineItemsConfig}
                  items={commercialInvoiceLineItems}
                  getDefaultItem={getDefaultCommercialInvoiceLineItem}
                  itemName="Commercial Invoice Line Items"
                  useHeader={false}
                  styles={commercialInvoiceLineItemsStyles}
                  useDynamicColumnHeader={true}
                  useDeleteConfirmationModal={true}
                />
                <div
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: '12px',
                    padding: '6px',
                    marginTop: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                  }}
                >
                  <div style={{ paddingRight: '32px', borderRight: '1px solid lightgrey' }}>Total</div>
                  <div>$35,000.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ImportalCard>
      {commercialInvoices.map((commercialInvoice, index) => {
        return (
          <div key={index}>
            {JSON.stringify(commercialInvoice)}
            <br />
          </div>
        );
      })}
    </Container>
  );
}
