import { useEffect } from 'react';
import NavMenu, { NavItem } from '@/shared-components/navMenu';
import VesselSnackbar from '@/components/call-to-action-snackbar/VesselSnackbar';
import { IconButton } from '@mui/material';
import { ToggleOff, ToggleOn } from '@mui/icons-material';
import './SideMenu.css';
import useIsMobile from '@/custom-hooks/mobile-device/isMobileDevice';

interface SideMenuProps {
  isExpanded: boolean;
  navItems: NavItem[];
  shouldShowNavItem: (navItem: NavItem) => boolean;
  handleOpenPlansModal?: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  setNavSelection: (navItem: NavItem) => void;
  navigate: (path: string) => void;
  navSelection: NavItem | null | undefined;
  vesselSnackbarOpen?: boolean;
  handleManifestModalOpen?: () => void;
  handleVesselSnackbarClose?: () => void;
  toggleMenu?: () => void;
  isBrokerApp: boolean;
  renderUsageSection?: () => JSX.Element | null;
}

const SideMenu: React.FC<SideMenuProps> = ({
  isExpanded,
  navItems,
  shouldShowNavItem,
  handleOpenPlansModal,
  open,
  anchorEl,
  handleClick,
  handleClose,
  setNavSelection,
  navigate,
  navSelection,
  vesselSnackbarOpen,
  handleManifestModalOpen,
  handleVesselSnackbarClose,
  toggleMenu,
  isBrokerApp = false,
  renderUsageSection,
}) => {
  const isMobileDevice = useIsMobile(() => {});
  const shouldMenuBeExpanded = !isBrokerApp
    ? !isMobileDevice // if not broker app expand menu on the desktop not mobile
    : isMobileDevice
      ? false // if broker app on mobile collapse the menu
      : isExpanded; // if broker app on desktop, use toggle

  useEffect(() => {
    if (isMobileDevice) {
      document.documentElement.style.setProperty('--left-menu-width-collapsed', '88px');
    } else {
      document.documentElement.style.setProperty('--left-menu-width-collapsed', '276px');
    }
  }, [isMobileDevice]);

  return (
    <div className="left-menu-container">
      <div className={`left-menu ${shouldMenuBeExpanded ? '' : 'collapsed'} ${isBrokerApp ? 'broker-app' : ''}`}>
        <div className="main-content">
          <div className={shouldMenuBeExpanded ? 'importal-logo-dashboard' : 'importal-logo-dashboard-collapsed'}>
            <img
              src={shouldMenuBeExpanded ? '/IMPORTAL.png' : '/importal-logo-white-no-text.png'}
              width={shouldMenuBeExpanded ? '185' : '52'}
              height={shouldMenuBeExpanded ? '120' : '52'}
              alt="logo"
            />
            {!isMobileDevice && shouldMenuBeExpanded && !isBrokerApp && renderUsageSection && renderUsageSection()}
          </div>
        </div>
        <NavMenu
          navItems={navItems.filter(shouldShowNavItem)}
          handleOpenPlansModal={handleOpenPlansModal}
          open={open}
          isMenuOpen={shouldMenuBeExpanded}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          onNavItemSelected={(navItem) => {
            const routerPathSansWildCard = navItem.routerPath.endsWith('/*')
              ? navItem.routerPath.slice(0, -2)
              : navItem.routerPath;
            setNavSelection(navItem);
            navigate(routerPathSansWildCard);
          }}
          isBrokerApp={isBrokerApp}
          selectedNavItem={navSelection?.displayName}
          underNavItemElement={
            vesselSnackbarOpen && shouldMenuBeExpanded && !isMobileDevice && !isBrokerApp ? (
              <VesselSnackbar
                handleManifestModalOpen={handleManifestModalOpen}
                handleClose={handleVesselSnackbarClose}
              />
            ) : undefined
          }
        />
        {isBrokerApp && !isMobileDevice && (
          <div className="menu-toggle-button-container">
            <IconButton onClick={toggleMenu && toggleMenu}>
              {isExpanded ? (
                <ToggleOn sx={{ fontSize: '26px', color: 'rgba(255, 255, 255, 0.70)' }} />
              ) : (
                <ToggleOff sx={{ fontSize: '26px', color: 'rgba(255, 255, 255, 0.70)' }} />
              )}
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
