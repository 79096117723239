import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card, CardContent, CircularProgress, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ContactUsModal from '@/components/contact-us-modal/ContactUsModal';
import { useAPI } from '@/api/APIContext';
import { datadogRum } from '@datadog/browser-rum';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import './PhoneAuthentication.css';

export default function PhoneAuthentication() {
  const navigate = useNavigate();
  const theme = useTheme();
  const api = useAPI();
  const activeUser = useActiveUser();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLoading = (value) => {
    setIsLoading(value);
  };
  const handleContactImportal = (e) => {
    setOpen(true);
  };

  const handleContactImportalClose = (e) => {
    setOpen(false);
  };

  function Copyright(props: any) {
    const theme = useTheme();
    return (
      <Typography
        variant="body2"
        color={theme.palette.primary.main}
        sx={{ marginTop: '0px' }}
        align="center"
        {...props}
      >
        <Link color="inherit">
          <a style={{ cursor: 'pointer' }} onClick={handleContactImportal}>
            Need Help? Give us a shout
          </a>
        </Link>
      </Typography>
    );
  }

  const authenticateUserCode = (code: any) => {
    if (isNaN(code)) {
      alert('Please enter valid code only digits!');
      console.error(`${code} is not a number!`);
      return;
    }

    let codeObject = { authCode: code };
    handleLoading(true);

    api
      .submitAuthenticationCode(codeObject)
      .then(async (response) => {
        datadogRum.addAction('validate-authentication-code', {});
        api.setToken(response.data.token);

        await activeUser.refreshUser();
        handleLoading(false);
        navigate('/dashboard');
      })
      .catch(function (error) {
        console.error('error submitting auth code');
        console.error(error);
        handleLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let code = `${data.get('digitOne')}${data.get('digitTwo')}${data.get(
      'digitThree'
    )}${data.get('digitFour')}${data.get('digitFive')}`;
    authenticateUserCode(code);
  };

  const autoTab = (e: any, id: string) => {
    if (e.target.value.length === 1) {
      document.getElementById(id)?.focus();
    }
  };

  return (
    <div>
      <Container sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <img src="importal-2.png" height="90" width="140" alt="logo" />
      </Container>

      <div className="card-container-signup">
        <Card>
          <CardContent>
            <div className="header-question">
              <div className="authentication-title">
                <Typography>An authentication code has been sent to your email</Typography>
              </div>
              <div className="code-form">
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid className="input-grid-container" container justifyContent="center">
                    <Grid
                      sx={{
                        maxWidth: '75px',
                        '@media (max-width: 480px)': {
                          maxWidth: '50px',
                        },
                      }}
                    >
                      <TextField
                        autoComplete="given-name"
                        name="digitOne"
                        required
                        fullWidth
                        id="digitOne"
                        onChange={(e) => {
                          autoTab(e, '1');
                        }}
                        autoFocus
                        className="autotab"
                        type="text"
                        inputProps={{ maxLength: 1 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        maxWidth: '75px',
                        '@media (max-width: 480px)': {
                          maxWidth: '50px',
                        },
                      }}
                    >
                      <TextField
                        required
                        fullWidth
                        id="1"
                        name="digitTwo"
                        className="autotab"
                        onChange={(e) => {
                          e.target.value.replace(/[^0-9]/g, '');
                          autoTab(e, '2');
                        }}
                        type="phone"
                        autoComplete="family-name"
                        inputProps={{ maxLength: 1 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        maxWidth: '75px',
                        '@media (max-width: 480px)': {
                          maxWidth: '50px',
                        },
                      }}
                    >
                      <TextField
                        required
                        fullWidth
                        id="2"
                        name="digitThree"
                        className="autotab"
                        onChange={(e) => {
                          autoTab(e, '3');
                        }}
                        autoComplete="family-name"
                        inputProps={{ maxLength: 1 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        maxWidth: '75px',
                        '@media (max-width: 480px)': {
                          maxWidth: '50px',
                        },
                      }}
                    >
                      <TextField
                        required
                        fullWidth
                        id="3"
                        name="digitFour"
                        className="autotab"
                        onChange={(e) => {
                          autoTab(e, '4');
                        }}
                        autoComplete="family-name"
                        inputProps={{ maxLength: 1 }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        maxWidth: '75px',
                        '@media (max-width: 480px)': {
                          maxWidth: '50px',
                        },
                      }}
                    >
                      <TextField
                        required
                        fullWidth
                        id="4"
                        name="digitFive"
                        className="autotab"
                        autoComplete="family-name"
                        inputProps={{ maxLength: 1 }}
                      />
                    </Grid>
                  </Grid>
                  <div className="phone-auth-button-container">
                    <Button
                      type="submit"
                      fullWidth
                      sx={{
                        textTransform: 'none',
                        backgroundColor: '#388E3C',
                        color: '#FDFDFD',
                        width: '100px',
                      }}
                      className="consultation-button"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress color="success" size={20} /> : 'Submit'}
                    </Button>
                  </div>
                </Box>
              </div>
            </div>
            <Container sx={{ marginBottom: '10px' }}>
              <Copyright />
            </Container>
          </CardContent>
        </Card>
      </div>
      <ContactUsModal open={open} onClose={handleContactImportalClose} />
    </div>
  );
}
