import React, { useEffect, useState } from 'react';
import ImportalCard from '@/shared-components/ImportalCard';
import { useAPI } from '@/api/APIContext';
import { Metric, Report, TimePeriod } from 'common/interfaces/reports';
import { useTheme } from '@mui/material';
import ImportalDatePicker from '@/shared-components/ImportalDatePicker';
import ImportalSimpleTable from '@/shared-components/ImportalSimpleTable';
import Link from '@mui/material/Link';
import { mmmddyyHmmDate } from '@/utils/activePlanUtils';
import { startOfMonth, subMonths } from 'date-fns';

export default function SummaryMetrics() {
  const theme = useTheme();
  const api = useAPI();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriod>({
    periodStartDate: null,
    periodEndDate: null,
  });

  const [metrics, setMetrics] = useState<Metric[]>();

  const onTimePeriodSelected = (timePeriod: TimePeriod) => {
    setSelectedTimePeriod(timePeriod);
  };

  useEffect(() => {
    if (!selectedTimePeriod || !selectedTimePeriod.periodStartDate || !selectedTimePeriod.periodEndDate) {
      // no selectedTimePeriod, make default of start of previous month to today
      const startOfPreviousMonth = startOfMonth(subMonths(new Date(), 1));
      setSelectedTimePeriod({
        periodStartDate: startOfPreviousMonth,
        periodEndDate: new Date(),
      });
      return;
    }

    api
      .getSummaryMetrics(selectedTimePeriod)
      .then(({ data }) => {
        setMetrics(data);
      })
      .catch((err) => {
        console.error('error getting summary metrics');
        console.error(err);
      });
  }, [selectedTimePeriod]);

  return (
    <>
      <ImportalCard
        title={'Metrics'}
        topRightActionButton={
          <ImportalDatePicker timePeriod={selectedTimePeriod} onTimePeriodSelected={onTimePeriodSelected} />
        }
      >
        {metrics && (
          <ImportalSimpleTable
            data={metrics}
            columns={['Title', 'Current Period']}
            renderListItem={(datum: Metric, index) => (
              <>
                <span>{datum.title}</span>
                <span>{datum.currentPeriodValue}</span>
              </>
            )}
          ></ImportalSimpleTable>
        )}
      </ImportalCard>
    </>
  );
}
