import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
import { Add, Check, Close, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Mode } from 'common/interfaces/business';
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal';
import { CSSObject } from '@mui/system';
import LightTooltip from '@/components/tooltip-component/TooltipComponent';
import FloatingTooltip from '../FloatingTooltip/FloatingTooltip';

// Update your type to use CSSObject
type ManageItemStyles = {
  itemsTableRow: CSSObject;
  entryCardHeaderContainer: CSSObject;
  myShipmentsHeader: CSSObject;
  itemsTableContainer: CSSObject;
  noResultsContainer: CSSObject;
  userRowInputBase: CSSObject;
  rowCellStyles: CSSObject;
  headerCellStyles: CSSObject;
  iconButton: CSSObject;
  icon: CSSObject;
};

const defaultStyles: ManageItemStyles = {
  itemsTableRow: {
    padding: '5px',
    paddingLeft: '0px',
    paddingRight: '16px',
    paddingBottom: '5px',
  },
  entryCardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  myShipmentsHeader: {
    fontWeight: 700,
  },
  itemsTableContainer: {
    marginTop: '16px',
    '& .MuiTableCell-root': {
      padding: '0px!important',
    },
  },
  noResultsContainer: {
    padding: '16px',
    textAlign: 'center',
    color: '#525256',
  },
  userRowInputBase: {
    borderRadius: '4px',
    background: 'white',
    fontSize: '14px',
    '& .MuiInputBase-root': {
      borderRadius: '4px!important',
      background: 'white!important',
      fontSize: '14px!important',
    },
  },
  rowCellStyles: {
    padding: '2px!important',
    textWrap: 'nowrap',
    '& .MuiTypography-root': {
      fontSize: '12px!important',
    },
    fontSize: '12px!important',
  },
  headerCellStyles: {
    padding: '2px!important',
    fontSize: '12px!important',
    fontWeight: '700',
    color: '#525256',
  },
  iconButton: {
    padding: '8px',
  },
  icon: {
    fontSize: '24px',
  },
};

const mergeStyles = (defaultStyles: ManageItemStyles, customStyles?: Partial<typeof defaultStyles>) => {
  return Object.assign({}, defaultStyles, customStyles);
};

export interface HeaderConfig {
  header: string;
  viewComponent: (item: any, setItem?: (arg0: any) => void) => React.JSX.Element;
  addComponent?: (item: any, setItem: (arg0: any) => void) => React.JSX.Element;
  editComponent: (item: any, setItem: (arg0: any) => void) => React.JSX.Element;
}

const alwaysUnique = (item) => {
  return Math.random() * 10000;
};

interface ManageItemsProps<T> {
  manageItemsConfig: HeaderConfig[];
  items: T[];
  getDefaultItem: () => T;

  itemKeyCheck?: (item: T) => any;

  onItemSaved?: (item: T, index: number) => void;
  onItemDeleted?: (item: T, index: number) => void;
  onItemsUpdated?: (items: T[]) => void;

  itemName: string;
  parentName?: string;
  fontSize?: string;
  useDeleteConfirmationModal?: boolean;
  editable?: boolean;
  style?: CSSObject;
  useHeader?: boolean;
  styles?: Partial<ManageItemStyles>;
  useDynamicColumnHeader?: boolean;
}

export default function ManageItems<T>({
  manageItemsConfig,
  items = [],
  getDefaultItem,

  itemKeyCheck = alwaysUnique,

  onItemSaved,
  onItemDeleted,
  onItemsUpdated,

  parentName,
  itemName,
  fontSize,
  useDeleteConfirmationModal,
  editable = true,
  style = {},
  useHeader = true,
  styles: customStyles = {} as ManageItemStyles,
  useDynamicColumnHeader = false,
}: ManageItemsProps<T>) {
  // Merge default styles with custom styles
  const mergedStyles = mergeStyles(defaultStyles, customStyles);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(0);

  const [localItems, setLocalItems] = useState<T[]>([]);
  const [itemModes, setItemModes] = useState<Mode[]>([]);
  const headerRefs = useRef<(HTMLTableCellElement | null)[]>([]);
  const [headerTexts, setHeaderTexts] = useState(Array(manageItemsConfig.length).fill(''));

  const syncItemModes = (updatedItems: T[], updatedModes: Mode[]) => {
    if (updatedItems.length !== updatedModes.length) {
      const newModes = [...updatedModes];
      while (newModes.length < updatedItems.length) {
        newModes.push(Mode.ADD);
      }
      setItemModes(newModes);
    } else {
      setItemModes(updatedModes);
    }
  };

  useEffect(() => {
    setLocalItems(items);
    setItemModes(items.map(() => Mode.VIEW));
  }, [editable]);

  useEffect(() => {
    const itemMap: Record<any, { item: T; mode: Mode }> = {};

    localItems.forEach((item) => {
      const key = itemKeyCheck(item);
      itemMap[key] = { item, mode: itemModes[localItems.indexOf(item)] };
    });

    items.forEach((item) => {
      const key = itemKeyCheck(item);
      if (itemMap.hasOwnProperty(key)) {
        const existing = itemMap[key];
        if (existing.mode !== Mode.VIEW) {
          itemMap[key] = { item, mode: existing.mode };
        } else {
          itemMap[key] = { item, mode: Mode.VIEW };
        }
      } else {
        itemMap[key] = { item, mode: Mode.VIEW };
      }
    });

    const newItems: T[] = [];
    const newModes: Mode[] = [];

    for (const { item, mode } of Object.values(itemMap)) {
      newItems.push(item);
      newModes.push(mode);
    }

    setLocalItems(newItems);
    setItemModes(newModes);
  }, [items]);

  const handleDeleteConfirmClose = (confirm: boolean) => {
    if (confirm) {
      handleDeleteItemRow(indexToDelete);
    }
    setDeleteConfirmOpen(false);
  };

  const setItemAtIndex = (item: any, index: number) => {
    const updatedItems = [...localItems];
    updatedItems[index] = item;
    setLocalItems(updatedItems);
  };

  const handleAddItemRow = () => {
    const updatedItems = [...localItems, getDefaultItem()];
    setLocalItems(updatedItems);
    syncItemModes(updatedItems, [...itemModes, Mode.ADD]);
  };

  const handleCancelEditItemRow = (indexToUpdate: number) => {
    const updatedItemModes = [...itemModes];
    updatedItemModes[indexToUpdate] = Mode.VIEW;
    setItemModes(updatedItemModes);
  };

  const handleEditItemRow = (indexToUpdate: number) => {
    const updatedItemModes = [...itemModes];
    updatedItemModes[indexToUpdate] = Mode.EDIT;
    setItemModes(updatedItemModes);
  };

  const handleSaveItemRow = (indexToUpdate: number) => {
    const currentMode = itemModes[indexToUpdate];
    const itemToSave: T = localItems[indexToUpdate];
    let updatedItems: T[] = [...items];

    if (currentMode === Mode.EDIT) {
      updatedItems[indexToUpdate] = itemToSave;
    } else {
      if (indexToUpdate > items.length - 1) {
        updatedItems.push(itemToSave);
      } else {
        const itemKeyInItems = itemKeyCheck(items[indexToUpdate]);
        const itemKeyInLocalItems = itemKeyCheck(itemToSave);

        if (itemKeyInItems === itemKeyInLocalItems) {
          updatedItems[indexToUpdate] = itemToSave;
        } else {
          updatedItems.splice(indexToUpdate, 0, itemToSave);
        }
      }
    }

    const updatedItemModes = [...itemModes];
    updatedItemModes[indexToUpdate] = Mode.VIEW;
    setItemModes(updatedItemModes);

    onItemSaved?.(itemToSave, indexToUpdate);
    onItemsUpdated?.(updatedItems);
  };

  const handleOpenDeleteConfirmModal = (index) => {
    setIndexToDelete(index);
    if (useDeleteConfirmationModal) {
      setDeleteConfirmOpen(true);
    } else {
      handleDeleteItemRow(index);
    }
  };

  const handleDeleteItemRow = (index: number) => {
    const currentMode = itemModes[index];

    if (currentMode === Mode.ADD) {
      const updatedItems = localItems.filter((_, i) => i !== index);
      const updatedModes = itemModes.filter((_, i) => i !== index);

      setLocalItems(updatedItems);
      syncItemModes(updatedItems, updatedModes);
    } else if (currentMode === Mode.VIEW) {
      const itemToDelete = localItems[index];
      const updatedItems = items.filter((item) => itemKeyCheck(item) !== itemKeyCheck(itemToDelete));

      const updatedLocalItems = localItems.filter((_, i) => i !== index);
      const updatedLocalModes = itemModes.filter((_, i) => i !== index);

      setLocalItems(updatedLocalItems);
      syncItemModes(updatedLocalItems, updatedLocalModes);

      onItemDeleted?.(itemToDelete, index);
      onItemsUpdated?.(updatedItems);
    }
  };

  function capitalizeString(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const responsiveHeaderText = (text, width) => {
    // Dynamically adjust the number of characters based on the width
    const characterWidth = 10; // Adjust this value as needed for your font
    const maxChars = Math.floor(width / characterWidth);
    return text.length > maxChars ? text.slice(0, maxChars) + '...' : text;
  };

  useEffect(() => {
    if (useDynamicColumnHeader) {
      const updateHeaderTexts = () => {
        const newHeaderTexts = manageItemsConfig.map((config, idx) => {
          const width = headerRefs.current[idx]?.offsetWidth || 0;
          return responsiveHeaderText(config.header, width);
        });
        setHeaderTexts(newHeaderTexts);
      };

      // Call the function initially and set up a listener for resizing
      updateHeaderTexts();
      window.addEventListener('resize', updateHeaderTexts);

      return () => {
        window.removeEventListener('resize', updateHeaderTexts);
      };
    }
  }, [manageItemsConfig, useDynamicColumnHeader]);

  const renderItemRow = (item: any, manageItemConfigs: HeaderConfig[], index: number, mode: Mode) => (
    <TableRow sx={mergedStyles.itemsTableRow} key={`${mode}-${index}`}>
      {manageItemConfigs.map((manageItemConfig) => (
        <TableCell sx={mergedStyles.rowCellStyles} key={`${manageItemConfig.header}-${index}-${mode}`}>
          {mode === Mode.VIEW && manageItemConfig.viewComponent(item)}
          {mode === Mode.ADD &&
            (manageItemConfig.addComponent?.(item, (item) => setItemAtIndex(item, index)) ||
              manageItemConfig.editComponent(item, (item) => setItemAtIndex(item, index)))}
          {mode === Mode.EDIT && manageItemConfig.editComponent(item, () => setItemAtIndex(item, index))}
        </TableCell>
      ))}
      {editable && (
        <TableCell sx={mergedStyles.rowCellStyles}>
          {mode === Mode.VIEW && (
            <>
              <IconButton size="small" onClick={() => handleEditItemRow(index)}>
                <EditIcon sx={mergedStyles.icon} />
              </IconButton>
              <IconButton size="small" onClick={() => handleOpenDeleteConfirmModal(index)}>
                <DeleteIcon sx={mergedStyles.icon} />
              </IconButton>
            </>
          )}
          {mode === Mode.EDIT && (
            <>
              <IconButton size="small" onClick={() => handleCancelEditItemRow(index)}>
                <Close sx={mergedStyles.icon} />
              </IconButton>
              <IconButton size="small" onClick={() => handleSaveItemRow(index)}>
                <Check sx={mergedStyles.icon} />
              </IconButton>
            </>
          )}
          {mode === Mode.ADD && (
            <>
              <IconButton size="small" onClick={() => handleDeleteItemRow(index)}>
                <Close sx={mergedStyles.icon} />
              </IconButton>
              <IconButton size="small" onClick={() => handleSaveItemRow(index)}>
                <Check sx={mergedStyles.icon} />
              </IconButton>
            </>
          )}
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <div>
      <Box sx={{ ...mergedStyles.entryCardHeaderContainer, ...style }}>
        <Box sx={mergedStyles.myShipmentsHeader}>{useHeader ? capitalizeString(itemName) : ''}</Box>
        {editable && (
          <IconButton onClick={handleAddItemRow}>
            <Add />
          </IconButton>
        )}
      </Box>
      {localItems && localItems.length > 0 ? (
        <Box sx={mergedStyles.itemsTableContainer}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {manageItemsConfig.map(({ header }, idx) => (
                    <TableCell
                      sx={mergedStyles.headerCellStyles}
                      key={idx}
                      ref={(el) => {
                        headerRefs.current[idx] = el as HTMLTableCellElement | null;
                      }}
                      className="responsive-header"
                    >
                      {useDynamicColumnHeader ? (
                        <FloatingTooltip title={header} placement="top">
                          <span>{headerTexts[idx] || header}</span>
                        </FloatingTooltip>
                      ) : (
                        header
                      )}
                    </TableCell>
                  ))}
                  {editable && <TableCell sx={mergedStyles.headerCellStyles}></TableCell>}
                </TableRow>
              </TableHead>

              <TableBody sx={mergedStyles.userRowInputBase}>
                {localItems.map((item, index) => renderItemRow(item, manageItemsConfig, index, itemModes[index]))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box sx={mergedStyles.noResultsContainer}>
          No {itemName} saved yet for this {parentName}.
        </Box>
      )}
      <DeleteConfirmationModal open={deleteConfirmOpen} title={'Delete Item'} onClose={handleDeleteConfirmClose} />
    </div>
  );
}
