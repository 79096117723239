import React, { useEffect, useState } from 'react';
import { useAPI } from '../../api/APIContext';
import Dialog from '@mui/material/Dialog';
import { Divider, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { ArrowUpward, AutoAwesome, Save } from '@mui/icons-material';
import { ProductField, ProductFieldsConfig } from 'common/interfaces/productfields';
import Checkbox from '@mui/material/Checkbox';

import './EditProductFieldsModal.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';

interface Props {
  open: boolean;
  onClose: () => void;
  onFieldsEdited: () => void;
}

export default function EditProductFieldsModal({ open, onClose, onFieldsEdited }: Props) {
  const api = useAPI();
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [productFieldsAreValid, setProductFieldsAreValid] = useState(false);

  const [productFields, setProductFields] = useState<ProductField[]>([]);

  const validateProductFields = () => {
    const primaryIdentifierFields = productFields.filter((field) => field.isPrimaryIdentifier);
    const duplicateNames = productFields
      .map((field) => field.name)
      .filter((name, index, arr) => arr.indexOf(name) !== index);
    const emptyNames = productFields.map((field) => field.name).filter((name) => name === '');

    if (primaryIdentifierFields.length === 1 && duplicateNames.length === 0 && emptyNames.length === 0) {
      setProductFieldsAreValid(true);
    } else {
      setProductFieldsAreValid(false);
    }
  };
  useEffect(() => {
    validateProductFields();
  }, [productFields]);

  useEffect(() => {
    setLoading(true);
    api
      .getProductFields()
      .then(({ data: productFieldsConfig }) => {
        setProductFields(productFieldsConfig.fields);
      })
      .catch((err) => {
        console.error('error getting product fields for edit modal');
        console.error(err);
      });
  }, []);

  const onFieldDataChanged = (
    index: number,
    fieldName: string,
    incomingValue: any,
    incomingValueType: string,
    event?: any
  ) => {
    const productField = productFields[index];
    const updatedProductFields = [...productFields];
    updatedProductFields[index] = {
      ...productField,
      [fieldName]: incomingValue,
    };
    setProductFields(updatedProductFields);
    setIsEdited(true);
  };

  const addFieldButtonClicked = () => {
    const newField: ProductField = {
      name: '',
      showInUI: true,
      fieldIsRequiredByImportal: false,
      dataType: 'string',
    };
    setProductFields([...productFields, newField]);
  };

  const onSubmit = () => {
    const updatedProductFields = {
      fields: productFields,
    } as ProductFieldsConfig;

    api
      .updateProductFields(updatedProductFields)
      .then(() => {
        setLoading(false);
        onFieldsEdited();
        onClose();
      })
      .catch((err) => {
        console.error('error updating product fields');
        console.error(err);
        setLoading(false);
      });
  };

  const onClickDeleteProductField = (index: number) => {
    const updatedProductFields = [...productFields];
    updatedProductFields.splice(index, 1);
    setProductFields(updatedProductFields);
    setIsEdited(true);
    validateProductFields();
  };

  const onClickColumnUp = (index: number) => {
    if (index === 0) {
      return;
    }
    const updatedProductFields = [...productFields];
    const tempField = updatedProductFields[index - 1];
    updatedProductFields[index - 1] = updatedProductFields[index];
    updatedProductFields[index] = tempField;
    setProductFields(updatedProductFields);
    setIsEdited(true);
  };

  const renderProductFieldConfig = (productField: ProductField, index: number): React.JSX.Element => {
    return (
      <React.Fragment key={index}>
        {productField.isPrimaryIdentifier && <h3>Primary Identifier</h3>}

        <div className="product-field-container">
          {productField.fieldIsRequiredByImportal && productField.isOutputColumn && (
            <Tooltip title="This field is calculated by Importal." followCursor={true}>
              <span>
                <h3>
                  {productField.name} <AutoAwesome fontSize={'small'} />
                </h3>
              </span>
            </Tooltip>
          )}
          {productField.fieldIsRequiredByImportal &&
            !productField.isOutputColumn &&
            !productField.isPrimaryIdentifier && (
              <Tooltip title="Importal uses this field to calculate your duties." followCursor={true}>
                <span>
                  <h3>{productField.name}</h3>
                </span>
              </Tooltip>
            )}
          {(!productField.fieldIsRequiredByImportal || productField.isPrimaryIdentifier) && (
            <TextField
              className="product-field-element"
              margin="normal"
              label="Name"
              value={productField.name}
              size="small"
              disabled={productField.fieldIsRequiredByImportal && !productField.isPrimaryIdentifier}
              onChange={(e) => onFieldDataChanged(index, 'name', e.target.value, 'string')}
            />
          )}

          {/*<TextField*/}
          {/*    className='product-field-element'*/}
          {/*    select*/}
          {/*    margin='normal'*/}
          {/*    label='Data Type'*/}
          {/*    value={productField.dataType}*/}
          {/*    size='small'*/}
          {/*    disabled={productField.fieldIsRequiredByImportal}*/}
          {/*    onChange={e => onFieldDataChanged(index, 'dataType', e.target.value, 'string')}*/}

          {/*>*/}
          {/*    {Object.values(ProductFieldDataTypes).map((dataType) =>*/}
          {/*        <MenuItem key={dataType} value={dataType}>{dataType}</MenuItem>*/}
          {/*    )}*/}

          {/*</TextField>*/}
          {/*<TextField*/}
          {/*    className='product-field-element'*/}
          {/*    margin='normal'*/}
          {/*    label='Default Value'*/}
          {/*    value={productField.defaultValue || ""}*/}
          {/*    size='small'*/}
          {/*    onChange={e => onFieldDataChanged(index, 'defaultValue', e.target.value, 'string')}*/}
          {/*/>*/}
          <div className="end-field-section">
            {!productField.isPrimaryIdentifier && index !== 1 ? (
              <IconButton
                onClick={() => {
                  onClickColumnUp(index);
                }}
              >
                <ArrowUpward></ArrowUpward>
              </IconButton>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="product-field-container">
          <FormControlLabel
            className="product-field-element"
            control={
              <Checkbox
                size="small"
                onChange={(e) => onFieldDataChanged(index, 'showInUI', e.target.checked, 'boolean', e)}
                checked={productField.showInUI}
              />
            }
            label="Show in UI"
          />
          <div className="end-field-section">
            {!productField.fieldIsRequiredByImportal ? (
              <IconButton
                onClick={() => {
                  onClickDeleteProductField(index);
                }}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
            ) : (
              <></>
            )}
          </div>
        </div>

        <Divider style={{ marginTop: '32px', marginBottom: '32px' }}></Divider>
      </React.Fragment>
    );
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={(event, reason) => {
          if (reason && reason === 'backdropClick') return;
          onClose();
        }}
      >
        {/*TODO: loading Backdrop not working*/}
        {/*<Backdrop*/}
        {/*    open={loading}*/}
        {/*    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>*/}
        {/*    <CircularProgress color="inherit"/>*/}
        {/*</Backdrop>*/}

        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: 'min( 1000px, calc(100vw - 400px))',
            maxWidth: 'calc(100vw - 200px)',
            minHeight: 'min(600px, calc(100vh - 400px))',
            maxHeight: 'calc(100vh - 200px)',
            margin: 'auto',
          }}
        >
          <div className="my-shipments-header">Product Fields</div>

          {productFields.map(renderProductFieldConfig)}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className="right-button-green" startIcon={<AddIcon />} onClick={addFieldButtonClicked}>
              Add Field
            </Button>
          </div>
        </DialogContent>

        <DialogActions>
          <ImportalPrimaryButton disabled={!isEdited || !productFieldsAreValid} onClick={onSubmit} text="Save" />
        </DialogActions>
      </Dialog>
    </>
  );
}
