import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTheme, Typography, Checkbox, DialogActions, Autocomplete, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import {
  ColumnConfig,
  ColumnFilterConfig,
  ColumnSortConfig,
  TableConfig,
  TableFilterConfig,
} from 'common/interfaces/importalTable';
import ManageItems, { HeaderConfig } from '../ManageItems/ManageItems';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ImportalTabs from '../ImportalTabs/ImportalTabs';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalSecondaryButton from '../ImportalSecondaryButton/ImportalSecondaryButton';
import Chip from '@mui/material/Chip';
import { SortDirection } from 'common/interfaces/importalTable';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { UserSettingName } from 'common/interfaces/user';

interface Props {
  open: boolean;
  onClose: () => void;
  onTableSettingsEdited: () => void;
  tableConfig: TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
  tableData: Array<any>;
  predefinedViews?: Array<TableConfig>;
}

export default function ImportalTableSettingsModal({
  open,
  onClose,
  onTableSettingsEdited,
  tableConfig,
  setTableConfig,
  tableData,
  predefinedViews,
}: Props) {
  const theme = useTheme();
  const [autocompleteInputFilter, setAutocompleteInputFilter] = useState('');
  const [autocompleteInputSort, setAutocompleteInputSort] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ key: string; name: string; value: string }[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<ColumnConfig | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedSortColumn, setSelectedSortColumn] = useState<ColumnConfig | null>(null);
  const [selectedSortDirection, setSelectedSortDirection] = useState<SortDirection | null>(null);
  const [selectedSorts, setSelectedSorts] = useState<ColumnSortConfig[]>([]);
  const activeUser = useActiveUser();
  const [views, setViews] = useState<TableConfig[]>(predefinedViews || []);

  useEffect(() => {
    setViews(predefinedViews || []);
  }, [predefinedViews]);

  useEffect(() => {
    setSelectedSorts(tableConfig.sortConfig || []);
  }, [tableConfig.sortConfig]);

  useEffect(() => {
    const filters: { key: string; name: string; value: string }[] = [];
    if (tableConfig.filterConfig) {
      Object.keys(tableConfig.filterConfig).forEach((key) => {
        const filter = tableConfig.filterConfig[key];
        filter.allowedValues.forEach((value) => {
          filters.push({ key: filter.columnName, name: filter.displayName as string, value });
        });
      });
    }
    setSelectedFilters(filters);
  }, [tableConfig.filterConfig]);

  const saveTableView = () => {
    const updatedFilters: TableFilterConfig = {};

    selectedFilters.forEach((filter) => {
      // Check if the filter column already exists in updatedFilters, and append the new value
      if (updatedFilters[filter.key]) {
        // If the column exists, add the value to the existing allowedValues array
        updatedFilters[filter.key].allowedValues.push(filter.value);
      } else {
        // Otherwise, create a new filter entry for the column
        updatedFilters[filter.key] = {
          columnName: filter.key,
          displayName: filter.name,
          allowedValues: [filter.value],
        };
      }
    });

    const updatedTableConfig: TableConfig = {
      ...tableConfig,
      viewName: 'Default',
      sortConfig: selectedSorts,
      filterConfig: updatedFilters, // Save the accumulated filter configuration
    };

    activeUser.setUserSetting(UserSettingName.SHIPMENT_TABLE_VIEWS, [updatedTableConfig]);
    onClose();
  };

  const tableSettingsConfig: HeaderConfig[] = [
    {
      header: 'Name',
      viewComponent: (item: ColumnConfig) => <Typography>{item.displayName}</Typography>,
      editComponent: () => <></>,
    },
    {
      header: 'Property',
      viewComponent: (item: ColumnConfig) => <Typography>{item.name}</Typography>,
      editComponent: () => <></>,
    },
    {
      header: 'Visible',
      viewComponent: (item: ColumnConfig) => (
        <Checkbox
          checked={item.isVisible}
          onChange={(e) => {
            const updatedColumns = tableConfig.columns.map((col) =>
              col.displayName === item.displayName ? { ...col, isVisible: e.target.checked } : col
            );
            setTableConfig({ ...tableConfig, columns: updatedColumns });
          }}
          sx={{ padding: '0px' }}
          icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: 20 }} />}
          checkedIcon={<CheckBoxIcon sx={{ fontSize: 20 }} />}
        />
      ),
      editComponent: () => <></>,
    },
  ];

  const getDefaultTableColumn = (): ColumnConfig => ({
    displayName: '',
    name: '',
    isPrimaryIdentifier: false,
    dataType: 'string',
    defaultValue: null,
    required: false,
    sortable: false,
    filterable: false,
    categorizer: undefined,
    columnFilterSortFunction: undefined,
    isVisible: true,
  });

  const getUniqueValuesForColumn = (columnName: string): string[] => {
    const uniqueValues = new Set<string>();
    tableData.forEach((row) => {
      if (row[columnName] !== undefined) {
        uniqueValues.add(row[columnName].toString());
      }
    });
    return Array.from(uniqueValues);
  };

  const handleFilterInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setAutocompleteInputFilter(newInputValue);
    setSelectedColumn(tableConfig.columns.find((col) => col.displayName === newInputValue) || null);
  };

  const handleSortInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setAutocompleteInputSort(newInputValue);
    setSelectedSortColumn(tableConfig.columns.find((col) => col.displayName === newInputValue) || null);
  };

  const handleValueChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setSelectedValue(newInputValue);
  };

  const handleAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (selectedColumn) {
      if (event.key === 'Tab' && selectedValue) {
        setSelectedFilters([
          ...selectedFilters,
          { key: selectedColumn.name as string, name: selectedColumn.displayName as string, value: selectedValue },
        ]);
        setSelectedColumn(null);
        setSelectedValue(null);
        setAutocompleteInputFilter('');
        event.preventDefault();
      }
    }
  };

  const handleSortAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (selectedSortColumn && selectedSortDirection) {
      if (event.key === 'Tab') {
        setSelectedSorts([
          ...selectedSorts,
          {
            columnName: selectedSortColumn.name,
            displayName: selectedSortColumn.displayName,
            direction: selectedSortDirection,
          },
        ]);
        setSelectedSortColumn(null);
        setSelectedSortDirection(null);
        setAutocompleteInputSort(''); // Reset sorting input
        event.preventDefault();
      }
    }
  };

  const handleDeleteFilter = (index: number) => {
    const updatedFilters = [...selectedFilters];
    updatedFilters.splice(index, 1);
    setSelectedFilters(updatedFilters);
  };

  const handleDeleteSort = (index: number) => {
    const updatedSorts = [...selectedSorts];
    updatedSorts.splice(index, 1);
    setSelectedSorts(updatedSorts);
  };

  const tableViewTab = () => {
    return (
      <>
        <div style={{ color: '#525256', fontWeight: '700' }}>Columns</div>
        <ManageItems<ColumnConfig>
          manageItemsConfig={tableSettingsConfig}
          items={tableConfig.columns}
          getDefaultItem={getDefaultTableColumn}
          itemKeyCheck={(item) => item.name}
          onItemsUpdated={(updatedColumns) => {
            setTableConfig({ ...tableConfig, columns: updatedColumns });
            onTableSettingsEdited();
          }}
          itemName={'Columns'}
          editable={false}
          style={{ fontSize: '12px' }}
          useDeleteConfirmationModal={false}
          useHeader={false}
        />
        <div style={{ color: '#525256', fontWeight: '700', marginTop: '16px', display: 'flex' }}>
          <div style={{ flex: '1' }}>
            <div>Sorting</div>
            <div style={{ display: 'flex', gap: '6px', marginTop: '8px', paddingRight: '10%' }}>
              <div style={{ flex: '1' }}>
                <Autocomplete
                  freeSolo
                  options={tableConfig.columns.filter((col) => col.sortable).map((col) => col.displayName || col.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type or select a column..."
                      onKeyDown={(e) => handleSortAutocompleteKeyDown(e)}
                      fullWidth={true}
                      sx={{
                        backgroundColor: 'transparent',
                        '& .MuiOutlinedInput-root': {
                          padding: '4px',
                          paddingLeft: '8px',
                          '& fieldset': {
                            border: '1px solid lightgrey',
                            borderRadius: '8px',
                          },
                          fontSize: '12px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '12px',
                        },
                      }}
                    />
                  )}
                  inputValue={autocompleteInputSort}
                  onInputChange={handleSortInputChange}
                />
              </div>
              <div style={{ flex: '1' }}>
                {selectedSortColumn && selectedSortColumn.name && (
                  <Autocomplete
                    freeSolo
                    options={[SortDirection.asc, SortDirection.desc]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select sort direction"
                        onKeyDown={(e) => handleSortAutocompleteKeyDown(e)}
                        fullWidth={true}
                        sx={{
                          backgroundColor: 'transparent',
                          '& .MuiOutlinedInput-root': {
                            padding: '4px',
                            paddingLeft: '8px',
                            '& fieldset': {
                              border: '1px solid lightgrey',
                              borderRadius: '8px',
                            },
                            fontSize: '12px',
                          },
                          '& .MuiInputBase-input': {
                            fontSize: '12px',
                          },
                        }}
                      />
                    )}
                    inputValue={selectedSortDirection || ''}
                    onInputChange={(event, newInputValue) => setSelectedSortDirection(newInputValue as SortDirection)}
                  />
                )}
              </div>
            </div>
            <div style={{ marginTop: '8px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {selectedSorts.map((sort, index) => (
                <Chip
                  key={index}
                  label={`${sort.displayName}: ${sort.direction}`}
                  onDelete={() => handleDeleteSort(index)}
                  sx={{
                    backgroundColor: '#77A8F6',
                    color: 'white',
                    borderRadius: '10px!important',
                    padding: '8px',
                    fontSize: '12px',
                    '& .MuiChip-deleteIcon': {
                      color: 'white',
                      fontSize: '18px',
                    },
                  }}
                />
              ))}
            </div>
          </div>
          <div style={{ flex: '1' }}>
            <div>Filtering</div>
            <div style={{ display: 'flex', gap: '6px', marginTop: '8px' }}>
              <div style={{ flex: '1' }}>
                <Autocomplete
                  freeSolo
                  options={tableConfig.columns.map((col) => col.displayName)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type or select a column..."
                      onKeyDown={(e) => handleAutocompleteKeyDown(e)}
                      fullWidth={true}
                      sx={{
                        backgroundColor: 'transparent',
                        '& .MuiOutlinedInput-root': {
                          padding: '4px',
                          paddingLeft: '8px',
                          '& fieldset': {
                            border: '1px solid lightgrey',
                            borderRadius: '8px',
                          },
                          fontSize: '12px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '12px',
                        },
                      }}
                    />
                  )}
                  inputValue={autocompleteInputFilter}
                  onInputChange={handleFilterInputChange}
                />
              </div>
              <div style={{ flex: '1' }}>
                {selectedColumn && selectedColumn.name && (
                  <Autocomplete
                    freeSolo
                    options={getUniqueValuesForColumn(selectedColumn.name)}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <TextField
                          {...params}
                          placeholder={`Select a value for ${selectedColumn.displayName}`}
                          onKeyDown={(e) => handleAutocompleteKeyDown(e)}
                          fullWidth={true}
                          sx={{
                            backgroundColor: 'transparent',
                            '& .MuiOutlinedInput-root': {
                              padding: '4px',
                              paddingLeft: '8px',
                              '& fieldset': {
                                border: '1px solid lightgrey',
                                borderRadius: '8px',
                              },
                              fontSize: '12px',
                            },
                            '& .MuiInputBase-input': {
                              fontSize: '12px',
                            },
                          }}
                        />
                      </div>
                    )}
                    inputValue={selectedValue || ''}
                    onInputChange={(event, newInputValue) => handleValueChange(event, newInputValue)}
                  />
                )}
              </div>
            </div>
            <div style={{ marginTop: '8px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {selectedFilters.map((filter, index) => (
                <Chip
                  key={index}
                  label={`${filter.name}: ${filter.value}`}
                  onDelete={() => handleDeleteFilter(index)}
                  sx={{
                    backgroundColor: '#77A8F6',
                    color: 'white',
                    borderRadius: '10px!important',
                    padding: '8px',
                    fontSize: '12px',
                    '& .MuiChip-deleteIcon': {
                      color: 'white',
                      fontSize: '18px',
                    },
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={onClose}>
      <IconButton
        aria-label="close"
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'grey',
        }}
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </IconButton>

      <DialogContent>
        <div className="my-shipments-header">Table Settings</div>
        <div className="my-shipments-subheader">
          Customize the columns, sorting, and filtering you want to see in the table with each view
        </div>
        <div style={{ marginTop: '12px' }}>
          <ImportalTabs
            inCard={true}
            tabsConfig={views.map((view) => ({
              title: view.viewName || 'Other',
              element: tableViewTab(),
            }))}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ marginTop: '32px' }}>
        <ImportalSecondaryButton onClick={onClose} style={{ color: `${theme.palette.primary.main}` }} text="Cancel" />
        <ImportalPrimaryButton onClick={saveTableView} text="Save" />
      </DialogActions>
    </Dialog>
  );
}
