import { Backdrop, CircularProgress, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import { Product, ProductAction } from 'common/interfaces/product';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import './ProductLibrary.css';

import { Add, Save } from '@mui/icons-material';
import { ProductField, ProductFieldsConfig } from 'common/interfaces/productfields';
import { AxiosError } from 'axios';
import { SpecialDataTypeAllowedValues } from 'common/interfaces/importalTable';

enum Mode {
  AddProduct,
  EditProduct,
}

type ValidationErrors = Array<string>;

interface Props {
  open: boolean;
  onClose: () => void;
  productID?: string;
  onProductCreatedOrModified: (arg0: string, arg1: ProductAction) => void;
  productFieldsConfig?: ProductFieldsConfig;
}

export default function AddOrEditAProductModal({
  open,
  onClose,
  productID,
  onProductCreatedOrModified,
  productFieldsConfig,
}: Props) {
  const api = useAPI();

  const [mode, setMode] = useState<Mode>(productID ? Mode.EditProduct : Mode.AddProduct);
  const [isEdited, setIsEdited] = useState(false);
  const [productUnderEdit, setProductUnderEdit] = useState<Product | undefined>();
  const [loading, setLoading] = useState(false);

  const [productIsValid, setProductIsValid] = useState(false);

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>([]);

  useEffect(() => {
    setProductIsValid(productUnderEdit !== undefined);
  }, [productUnderEdit]);

  useEffect(() => {
    setIsEdited(false);

    if (!productID) {
      setMode(Mode.AddProduct);
      const product = {
        properties: {},
      };
      // @ts-ignore
      setProductUnderEdit(product);
    } else {
      setMode(Mode.EditProduct);
      setLoading(true);
      api
        .getProductByID(productID)
        .then(({ data: product }) => {
          setProductUnderEdit(product);
        })
        .catch((err) => {
          console.error('error getting product for add/edit modal');
          console.error(err);
        });
    }
    setLoading(false);
  }, [productID]);

  const onFieldChange = (fieldName, newValue) => {
    if (!productUnderEdit) return;
    const updatedProduct = { ...productUnderEdit };
    updatedProduct.properties[fieldName] = newValue;
    setProductUnderEdit(updatedProduct);
    setIsEdited(true);
  };

  const onSubmit = () => {
    setLoading(true);
    if (mode === Mode.AddProduct) {
      api
        .createProduct(productUnderEdit!)
        .then(({ data: product }) => {
          onProductCreatedOrModified(product._id, ProductAction.CREATED);
          setMode(Mode.EditProduct);
        })
        .catch((err) => {
          console.error('error creating product');
          console.error(err);
        });
    } else if (mode === Mode.EditProduct) {
      api
        .updateProduct(productID!, productUnderEdit)
        .then(() => {
          onProductCreatedOrModified(productID!, ProductAction.MODIFIED);
          onClose();
        })
        .catch((err) => {
          console.error('error updating product');
          console.error(err);
          if ((err as AxiosError).response?.data) {
            //@ts-ignore
            const { errors } = err.response.data;
            setValidationErrors(errors);
          }
        });
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={(event, reason) => {
          if (reason && reason === 'backdropClick') return;
          onClose();
        }}
      >
        {/*TODO: loading Backdrop not working*/}
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: 'min( 1000px, calc(100vw - 400px))',
            maxWidth: 'calc(100vw - 200px)',
            minHeight: 'min(600px, calc(100vh - 400px))',
            maxHeight: 'calc(100vh - 200px)',
            margin: 'auto',
          }}
        >
          <Typography variant="h6" gutterBottom component="div">
            Product Details
          </Typography>

          {validationErrors?.length > 0 && (
            <ul style={{ color: 'red' }}>
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}

          {productFieldsConfig?.fields.map((field: ProductField) => {
            return (
              <Tooltip
                key={field.name}
                title="This field is calculated by Importal"
                followCursor={true}
                disableHoverListener={!field.isOutputColumn}
              >
                <>
                  {field.dataType !== 'string' && (
                    <TextField
                      id={`product-field-${field.name}`}
                      key={field.name}
                      label={field.name}
                      select
                      margin="normal"
                      value={productUnderEdit?.properties[field.name] || ''}
                      size="small"
                      disabled={field.isOutputColumn}
                      onChange={(e) => onFieldChange(field.name, e.target.value)}
                    >
                      {SpecialDataTypeAllowedValues[field.dataType]?.map((allowedValue) => (
                        <MenuItem key={allowedValue} value={allowedValue}>
                          {allowedValue}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  {field.dataType === 'string' && (
                    <TextField
                      id={`product-field-${field.name}`}
                      key={field.name}
                      label={field.name}
                      disabled={field.isOutputColumn}
                      margin="normal"
                      size="small"
                      value={productUnderEdit?.properties[field.name] || ''}
                      defaultValue={field.defaultValue}
                      onChange={(e) => onFieldChange(field.name, e.target.value)}
                      fullWidth
                    />
                  )}
                </>
              </Tooltip>
            );
          })}
        </DialogContent>

        <DialogActions>
          <Button
            id="submit-product-changes-button"
            startIcon={mode === Mode.AddProduct ? <Add /> : <Save />}
            className="right-button-green"
            disabled={!isEdited || !productIsValid}
            onClick={onSubmit}
          >
            {mode === Mode.AddProduct ? 'Add Product' : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
